.contacts-container {
  width: 80%;
  margin: 40px auto;
}

.contacts-container .centre {
  display: block;
}

.contacts-container .centre .rev-link {
  display: inline;
  word-break: keep-all;
  font-weight: bold;
  font-size: 1.1em;
  color: #5293ea;
  cursor: pointer;
  transition: ease-in 0.15s;
  transition-property: opacity;
}

.contacts-container .centre .rev-link:hover {
  color: #466fa5; 
}

.sqr-pi {
  opacity: 0.1;
}
