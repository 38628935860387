/* css for my_tickets view */

.ticket {
  width: 96%;
  margin: 0 auto;
  max-width: 880px;
}

.ticket-head {
  margin-top: 16px;
  border: 2px solid green;
}

.ticket-head h2 {
  margin: 4px;
}

.ticket-theme {
  display: block;
  border: 2px solid green;
  padding: 6px 28px 4px;
  background-color: floralwhite;
  border-top: none;
}

.ticket-theme a {
  float: right;
}

.ticket-status {
  float: left;
}

.floated-urls {
  margin: 28px auto 5px;
}

.ticket-theme1 {
  font-weight: 600;
  color: rgb(59, 114, 218);
}

.next-tickets-page {
  display: block;
  padding: 5px;
  border: 1px solid black;
  background-color: aliceblue;
  width: fit-content;
  border-radius: 10px;
  margin: 26px auto;
  cursor: pointer;
}

.next-tickets-page:hover {
  background-color: blanchedalmond;
}

@media (min-width: 769px) {
  .ticket {
    width: 86%;
  }
}