
/* Default styles for admin tabs and content */

.admin-tab-content {
  display: none;
  margin: 0 auto;
  padding: 6px 12px;
}

.admin-tabs {
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
}

.admin-tabs > button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.admin-tabs > button:hover {
  background-color: #ddd;
}

.admin-tabs > button.active {
  background-color: #ccc;
}