
/* Admin Login Panel */

.del-btn-bin {
  width: 25px;
  height: 28px;
  display: block;
  background: white;
  border-radius: 8px;
  margin: auto;
  margin-top: 2px;
}

.delete-from-db span {
  margin: 4px 2px;
  display: block;
}
 
.adForm {
  width: 96%;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 40px 10px;
  border: 1px solid #e1eadc;
}

.adForm > label,
.sec-code label {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #859c7b;
}

.not_arobot {
  display: block;
  width: fit-content;
  margin: 20px auto;
}

.admBtn {
  border-radius: 5px;
  min-width: 110px;
  padding: 12px 8px;
}

.no-outsiders {
  color: #ababab;
  font-size: 0.8em;
}

.sec-code {
  width: 46%;
  margin: auto;
}

#codeform {
  display:  none;
}


@media (min-width: 769px) {
  .adForm {
    width: 46%;
  }
}