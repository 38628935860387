/* css for suggest_translation view */

.search-by-dictionary {
  margin: 10px auto 0;
  width: 100%;
  display: flex;
}

.search-by-dictionary input {
  margin: 0;
}

.search-by-dictionary button {
  padding: 6px 24px; 
}

.found-words {
  margin-top: 20px;
  font-size: 1.2em;
  color: #1b3d9a;
}

.search-list {
  width: 100%;
}

.search-list li {
  display: flex;
  padding: 2px 10px;
  margin: 6px auto;
  color: black;
  border-radius: 5px;
}

.search-list li:nth-child(even) {
  background-color: rgb(211, 245, 195);
}

.search-list li:nth-child(odd) {
  background-color: rgb(255, 252, 226);
}

.search-list li:not(:first-child):hover {
  background-color: lightblue;
  cursor: pointer;
}

.search-list li:first-child {
  border: 1px solid #b5e6b5;
  background-color: #b5d893;
  font-weight: bold;
}

.search-list li span {
  text-align: left;
  padding: 2px 12px;
}

.search-list li span:nth-of-type(1) {
  width: 20%;
}

.search-list li span:nth-of-type(2) {
  width: 25%;
}

.search-list li span:nth-of-type(3) {
  width: 55%;
}