/* css for forgot_password view */

.password-container {
  display: block; 
  margin: 60px auto; 
  width: 96%;
}

.password-container label {
  display: block;
  font-size: 1.15em;
  margin-bottom: 4px;
}

@media (min-width: 769px) {
  .password-container {
    width: 50%;
  }
}