/* css for suggested_words view */

.stat-of-words {
  padding-left: 20px;
  width: fit-content;
  margin: 45px auto 0;
  text-align: left;
  font-size: 1.2em;
  border-left: 5px solid lightsteelblue;
}

.totalw-approved {
  color: #09b509;
}
.totalw-disapproved {
  color: #ec5555;
}

.suggested-area {
  margin-top: 26px;
  font-size: 1.2em;
}

.sugg-wordstb {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.sugg-wordstb thead th{
  padding: 10px;
}

.sugg-wordstb tbody tr,
.sugg-wordstb tbody td {
  border: 1px solid rgb(95, 93, 93);
}

.sugg-wordstb tbody td {
  padding: 8px 0;
}

.sugg-wordstb tbody tr:nth-child(odd) {
  background-color: beige;
}

.sugg-wordstb tbody tr:hover {
  cursor: pointer;
  background-color: rgb(255, 208, 147);;
}