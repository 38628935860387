/* css for add text view */

.edit-txt-main-msg {
  display: block;
  margin: 12px auto 18px;
}

.separate-button-and-sorting {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sort-inpts {
  text-align: left;
}

.add-new-text-btn {
  cursor: pointer;
  border: none;
  padding: 8px;
  border-radius: 4px;
  color: rgb(11, 78, 11);
  background-color: #6be253
}

.add-new-text-btn:hover {
  background-color: #97f086;
}

.all-published-texts {
  padding-left: 0;
}

.all-published-texts li:nth-of-type(odd) {
  background-color: rgb(224, 252, 198);
}

.all-published-texts li:hover {
  background-color: rgb(191, 228, 157);
}

.all-published-texts li {
  border-radius: 4px;
  cursor: pointer;
  padding-left: 12px;
}

.li-flex-one-txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
}

.text-preview  {
  width: 90%;
  display: inline-flex;
}

.text-preview span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  text-align: left;
}

.text-preview > span:first-child {
  width: 20%;
}

.text-preview > span:last-child {
  width: 80%;
  max-height: 21px;
}

.deletion-span {
  border-radius: 4px;
  cursor: pointer;
  color: red;
  font-size: 1.5em;
  padding: 0 10px;
}

/* for modal */
.text-modal {
  background-color: #fefefe;
  margin: 6% auto;
  border: 1px solid #888;
  width: 95%;
  padding: 30px 28px 26px;

}

.text-modal > h1 {
  margin-top: 0;
}

.text-modal-fieldset {
  margin: 8px 0 10px;
}

.text-modal-fieldset input[type="radio"] {
  margin: 0 4px 0 12px;
}

.text-modal-input-for-head {
  font-size: 1.22em;
}

.text-modal-label-tbody {
  margin-bottom: 5px;
  display: block;
}

.text-modal-tarea-tbody {
  width: 100%;
  font-size: 1.24em;
}

.text-modal-btns-div {
  display: flex;
  margin: 24px auto 6px;
  justify-content: space-around;
  width: 60%;
}

.edit-btns-div button {
  min-width: 126px;
}

.text-modal-btns-div button:first-child {
  background-color: #81c873;
}

.text-modal-btns-div button:first-child:hover {
  background-color: #6dd358;
}

.text-modal-btns-div button:last-child {
  background-color: #cac6c6;
}

.text-modal-btns-div button:last-child:hover {
  background-color: #b1acac;
}

@media(min-width: 769px) {
  .text-modal {
    padding: 30px 58px 46px;
    width: 64%;
  }
}