/* Popup container */
.read-popup {
  position: relative;
  cursor: pointer;
  text-indent: 0;
}

/* The actual popup (appears on top) */
.read-popup .read-popuptext {
  font-size: 0.9em;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 6px;
  width: max-content;
  /* Position the tooltip */
  position: absolute;
  bottom: 180%;
  left: 50%; 
}

/* Popup arrow */
.read-popup .read-popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.read-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

#read-hr {
  background-color: antiquewhite;
  border: none;
  height: 1px;
  padding: 0;
  margin: 0.1rem auto;
  width: 90%;
}

[transcr] {
  color: orange;
  padding: 0px;
  margin-top: -6px;
}

[transl] {
  margin-top: -5px;
  padding: 0px;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}