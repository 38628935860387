/* css for change password view */

.user-chPassform {
  width: 96%;
  margin: 46px auto;
}

.changepass-btn {
  border-radius: 5px;
  min-width: 120px;
  margin: 15px 0;
}

@media (min-width: 769px) {
  .user-chPassform {
    width: 59%;
  }
}