/* css for view_profile view */

.user-profile {
  padding: 20px;
  background-color: ivory;
  min-height: 40px;
  display: flex;
  flex-direction: column;
}

.user-profile .p-head {
  margin: 4px auto 20px;
  color: #7c603b
}

.user-profile .user-nm {
  font-size: 1.6em;
  font-weight: bold;
}

.member {
  transition: transform .2s;
  width: 16%;
  margin-top: 5px;
  margin-bottom: -18px;
  opacity: .9;
}

.member:hover {
  transform: scale(1.2);
  opacity: 1;
}

.profile-img-stat {
  display: flex;
  justify-content: space-evenly;
}

.profile-i {
  width: 40%;
  background-color: aliceblue;
  border-top-left-radius: 10px;
  padding: 25px 4px;
}

.user-profile .usr-name {
  margin: 0 auto 6px;
  font-size: 1.4em;
}

.user-profile .usr-lvl {
  margin: 0;
  margin: 0;
  color: tomato;
  font-size: 1.05em;
}

.user-profile .usr-belt {
  width: 52%;
  margin: 18px 0 2px;
  transition: transform .2s;
}

.user-profile .usr-belt:hover {
  transform: scale(1.04);
}

.user-profile .last-seen {
  color: #757373;
  display: block;
  margin-top: 10px;
  margin-bottom: 4px;
}

.profile-stat {
  width: 60%;
  color: #2b6567;
  background-color: #e7f5d4;
  border-top-right-radius: 10px;
  padding: 25px 4px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.profile-stat p {
  width: 90%;
  font-weight: 700;
  background-color: #d8e6b7;
  border-radius: 9px;
  padding: 10px;
  margin: 0px;
}

.profile-stat p:not(:last-child) {
  margin: 0 auto 10px;
}

.profile-last-acts {
  padding: 25px 4px;
  background-color: #fffad0;
  color: #8e6633;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sex.view {
  display: inline-flex; 
  margin-top: 12px; 
  text-align: left;
}

.sex-wrap-view {
  margin-top: 6px;
  text-align: center;
}

.birth-date {
  color: #eca233;
}

.view-bio {
  color: #a77070;
  background-color: lightyellow;
  width: 84%;
  text-align: center;
  margin: auto;
  padding: 9px 4px;
  border-radius: 7px;
}