/* css for userprofile page */

.profile {
  box-sizing: border-box;
  background-color: rgb(239, 238, 247);
  border-radius: 12px;
  padding: 16px 2px 26px;
  margin: 0 auto;
  width: 96%;
}

.hello-header {
  color: #877ba7;
}

.username {
  color: #9c6579;
}

.profile-hr {
  width: 46%;
  border: 1px solid #dfe3f0;
}

.profile-menu {
  width: 80%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile-menu > li {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(230, 140, 76);
  margin: 6px 1px;
  padding: 5px 10px;
  box-shadow: 3px 3px 8px rgb(75, 74, 74);
}

.profile-menu > li > img {
  height: 18px;
  margin: 2px;
  opacity: 0.65;
}

.profile-menu li:hover {
  background-color: rgb(240, 144, 76);
  box-shadow: 2px 2px 8px rgb(42, 42, 42);
}

.profile-menu a {
  margin-left: 2px;
  color: rgb(255, 255, 255);
  font-size: 1.1em;
}

.info-col {
  height: 220px;
  margin: 16px auto 0;
  width: 96%;
  text-align: center;
  vertical-align: top;
}

.upperbox,
.bottombox {
  padding: 6px 0;
  background-color: rgba(212, 200, 245, 0.43);
  width: 86%;
}

.upperbox {
  padding-top: 14px;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid rgb(207, 213, 223);
}

.bottombox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  min-height: 60px;
  margin: 0 auto;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(206, 227, 240);
}

.bottombox:hover {
  background-color: rgb(183, 221, 245);
}

.numofwords {
  margin: 2%;
  font-size: 3.6em;
  text-shadow:
   -1px -1px 0 rgb(63, 63, 63),  
    1px -1px 0 rgb(83, 83, 83),
    -1px 1px 0 rgb(80, 80, 80),
     1px 1px 0 rgb(82, 82, 82);
  color: rgb(159, 158, 206);
}

.level-heading {
  margin: 0;
}

.belt-have {
  display: block;
  padding-left: 10px;
  margin: 0px;
}

.belt-img {
  margin-top: 12px;
  height: 84px;
  width: 112px;
  opacity: 0.94;
}

.img-belts {
  width: 111px;
  height: 78px;
  padding: 0px 1px;
  margin: 13px 12px 0px 12px;
  opacity: 0.94;
}

.user-info {
  word-break: break-all;
  padding: 24px 5% 32px;
  width: 86%;
  margin: 0 auto;
  border-radius: 20px;
  height: 100%;
  background-color: rgba(212, 200, 245, 0.43);
}

.date-of-register {
  margin-top: 16px;
  margin-bottom: 5px;
}

.register-date {
  margin: 3px;
}

.send-letter-btn.disabled {
  cursor: not-allowed;
}

.send-letter-btn {
  cursor: pointer;
  border: none;
  background-color: #ca8585;
  border-radius: 7px;
  padding: 5px 10px;
  color: #e6e6e6;
  min-width: 170px;
}

.send-letter-btn:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(214, 100, 100);
}

.checkmark {
  width: 25px;
  margin-bottom: -7px;
}

.email-unconfirmed {
  color: rgb(235, 38, 38);
  display: block;
  margin-bottom: 8px;
}

.email-confirmed {
  color: #609a51;
}

.emailHead {
  margin: 0;
}

.letter-sent-msg {
  display: none;
  color: #539c35;
  margin-top: 6px;
}

.user-email {
  display: block;
  color: rgb(18, 107, 195);
  margin-bottom: 12px;
}

/* css for progress bar  */

#align-bar {
  width: 80%;
  margin: 0 auto;
}
#myProgress {
  height: 20px;
  position: relative;
  width: 100%;
  background-color: rgb(212, 211, 228);
}

#myProgress i {
  top: 0;
  position: relative;
  z-index: 2;
  color: white;
}
#myBar {
  z-index: 1;
  display: initial;
  left: 0;
  position: absolute;
  height: 20px;
  background-color: rgb(63, 191, 67);;
}

/* profile css for info slides */

/* Slideshow container */
.slideshow-container {
  margin: auto;
  width: 95%;
  height: 36%;
  position: relative;
}

/* Hide the images by default */
.mySlides {
  box-sizing: border-box;
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #2e2e2e;
  top: 18%;
  bottom: 8px;
  left: -7px;
  width: 90%;
  margin: 4px auto;
  padding: 5px 10px 10px;
  text-align: center;
}
.text h3 {
  color: rgb(108, 108, 141);
}

.close-slides {
  position: absolute;
  top: 0;
  left: 2%;
  color: rgb(85, 85, 85);
  cursor: pointer;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 10px 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.user-belts-info-a {
  text-decoration: none;
  color: rgb(92, 0, 153);
  cursor: pointer;
}

/* Modal Content Box */
.modal-content-userprof {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 14px;
  width: 96%; /* Could be more or less, depending on screen size */
}

.modal-content-userprof h2 {
  color: rgb(71, 66, 85);
  margin-top: 0px;
}

.bt-table {
  width: 94%;
  margin: 1% auto;
  border-collapse: collapse;
}

.bt-table td {
  padding: 6px;
}

.bt-table tr:not(:last-child) {
  border-bottom: 2px solid rgb(238, 191, 115);
}

.user-main-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 1.1em;
  padding: 24px 4px 0px;
  color: #333333;
}

.user-data-main {
  max-width: 66%;
}

.sex.prof {
  display: inline-flex;
}

.prof-name {
  font-size: 1.2em;
  padding: 0 20px 15px;
  display: block;
  color: #8a5e6e;
}

.prof-birthdate {
  display: block;
  color: #5e9e53;
  padding: 0 20px 10px;
}

.prof-sex {
  padding: 0 20px;
}

.prof-about {
  padding: 8px 20px 0px;
  display: inline-block;
}

.prof-bio {
  color: #a09e35;
  background-color: whitesmoke;
  padding: 8px 20px;
  border-radius: 6px;
  margin: 8px 10px;
  margin-left: 20px;
  display: inline-block;
}

.prof-img {
  width: 15%;
  margin-right: 10%;
  transition: transform 0.2s;
  cursor: help;
}

.mem-lock {
  width: 12%;
}

.prof-img:hover {
  transform: scale(1.1)
}

.user-statistic,
.user-main-info {
  background-color: rgb(228, 221, 246);
  border-radius: 16px;
  margin: 24px;
  padding: 25px 25px 20px;
}

.user-statistic h3 {
  color: #4d4356;
  margin-top: 0;
}

.statistic-fl {
  display: flex;
  flex-direction: column;
}

.total-trainings {
  margin: 16px;
  background-color: #f5f3df;
  border-radius: 7px;
  padding: 10px;
  display: block;
  font-size: 1.1em;
  color: #6d72aa;
}

.total-trainings b:first-child {
  margin-right: 25px;
}

.total-trainings b:last-child {
  padding: 3px 10px;
  border-radius: 8px;
  background-color: #a0a0d2;
  color: #fdfdfd;
}

.total-sqr {
  background-color: #d0cff7;
  border-radius: 10px;
  color: #3e3e3e;
  padding: 10px;
  margin: 4px;
  min-width: 20%;
}

.other-statistics {
  padding: 10px;
  color: white;
  background-color: #a6b5d9;
  margin: 14px;
  border-radius: 10px;
}

.modal-sett-content {
  width: 55%;
  max-width: 876px;
  margin: 10% auto;
  background-color: #f7faff;
  padding: 21px 50px 46px;
}

.modal-sett-head {
  margin-top: 0;
  margin-bottom: 28px;
  color: #585e5f;
}

.modal-sett-center {
  width: 80%;
  margin: 2px auto;
}

.modal-sett-center label {
  display: block;
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  font-size: 1.1em;
  color: #d6b489;
}

.male,
.female {
  padding: 6px;
  cursor: pointer;
  color: #0a0a0a;
  padding: 2px 10px;
  opacity: 0.2;
}

.male {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #a8c3ff;
  background-color: #91b3ff;
  border-right: none;
}

.male:hover {
  opacity: 1;
  background-color: #a8c3ff;
}

.female {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffaee6;
  border: 1px solid #e6b8d8;
  border-left: none;
}

.female:hover {
  opacity: 1;
  background-color: #ffaee6;
}

.female.active,
.male.active  {
  opacity: 1;
  color: white;
}

.modal-sett-center .sex {
  display: inline-flex;
  margin-left: 20px;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 6px;
}

.sex-wrap {
  text-align: left;
  margin-top: 20px;
}

.sex-info {
  display: inline;
  margin-left: 15px;
  color: #989595;
}

.birth-input {
  text-align: left;
  margin-top: 20px;
}

.bio {
  text-align: left;
  display: flex;
  align-items: baseline;
}

.bio textarea {
  width: 75%;
  padding: 4px 6px;
  min-height: 65px;
  margin-left: 20px;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@media (min-width: 869px) {

  .profile {
    width: 94%;
    background: linear-gradient(to bottom left, rgb(239, 238, 247) 80%, ghostwhite);
  }

  .profile-menu {
    flex-direction: row;
    width: 100%;
  }

  .profile-menu li {
    margin: 0 1%;
  }

  .upperbox {
    height: 160px;
    margin: 0;
    width: 100%;
  }
  .bottombox {
    height: 60px;
    width: 100%;
  }

  .info-col {
    height: 220px;
    margin: 0 5px;
    width: 30%;
    text-align: center;
    vertical-align: top;
  }

  .center-cols {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px auto 32px;
  }

  .user-info {
    width: 100%;
  }

  .modal-content-userprof {
    width: 40%;
  }

  .statistic-fl {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
  }

}
