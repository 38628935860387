/* CSS FOR collect_word view */

.wrapper-collect {
  background-color: #f9f1eb;
  min-height: 400px;
  padding: 16px 30px 30px;
  border-radius: 17px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 2px 1px 6px #d4d4d4;
}

.wrapper-collect .train-info {
  color: #5b695c;
  font-size: 1.2em;
}

.start-collect-bt {
  width: fit-content;
  margin: 0 auto;
  padding: 12px 30px;
  background-color: #afbde6;
  cursor: pointer;
  color: white;
  border: none;
}

.start-collect-bt:hover {
  background-color: #9aace1;
}

.collected-word {
  display: flex;
  justify-content: center;
  background-color: tomato;
  min-height: 20px;
  border-radius: 10px;
  padding: 2px 13px;
  background-color: #c77465;
  min-height: 20px;
}

.word-hieros { 
  min-height: 50px;
  border-bottom: 2px dashed gray;
}

.collect-progress {
  margin: 0px 10px 14px;
  font-size: 1.1em;
  color: #5a5959;
}

.word-hiero-transl {
  padding: 8px 18px;
  background: white;
  color: #272525;
  font-size: 1.1em;
  width: fit-content;
  margin: 0 auto;
}

.hiero-span {
  margin: 4px;
  background-color: #9acd32;
  padding: 4px 9px;
  border-radius: 7px;
  color: cornsilk;
  font-size: 1.35em;
  font-weight: bold;
  cursor: pointer;
}

.empty-hiero-span {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  background-color: #ffeaea;
  border-radius: 6px;
  margin: 4px 2px;
}

.filled-hiero {
  width: 40px;
  height: 40px;
  margin: 4px 2px;
  font-size: 1.35em;
  background-color: #9acd32;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.filled-hiero span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wrong-collection:hover,
.right-collection:hover {
  cursor: pointer;
  opacity: 0.5;
}

.wrong-collection {
  background-color: #ff9d9d;
  padding: 4px 12px;
  color: white;
}

.right-collection {
  background-color: #a3ca53;
  padding: 4px 12px;
  color: white;
}