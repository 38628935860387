/* css for all words view */

.delete-from-db {
  background-color: #983838 !important;
  opacity: 0.8 !important; 
}

.w-act-btn {
  padding: 8px 12px !important;
  margin: 0px 10px !important;
}

.sorting-panel > select {
  padding: 2px 10px;
  margin: 2px;
}

.clear-filters-btn {
  margin: 12px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #404040;
  background: #e4e4e4;
}

.clear-filters-btn:hover {
  background-color: #cccccc;
  color: #f5f5f5;
}

.search-w-input-wrap {
  text-align: right;
}

.add-btn-and-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-btn-and-label > button {
  background-color: #cab751ab;
  color: #fff;
  border: none;
  margin: 4px;
  padding: 8px 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.add-btn-and-label > button:hover {
  background-color: #deca5d;
  transform: scale(1.1);
}

.exact-match-search {
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
  margin-bottom: 24px;
}

.exact-match-search input {
  transform: scale(1.2);
  margin-bottom: 1px;
  margin-left: 8px;
}

.list-of-all-words {
  margin-top: 12px;
  font-size: 1.2em;
}

.one-li {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  padding: 4px;
  border-radius: 4px;
}

.list-head {
  color: #444141;
  padding: 4px;
  background-color: #ece8cf;
  font-weight: 700;
  font-size: 1.15em;
  border: 1px solid #dadada;
}

.one-li:nth-child(odd):not(:first-child) {
  background-color: #efeee8;
  transition: transform 0.2s;
}

.one-li:not(:first-child):hover {
  background-color: #e8dfb0;
  color: #582a2a;
  transform: scale(1.02);
  cursor: pointer;
}

.one-li span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.one-li span:nth-of-type(1) {
  width: 30%;
}

.one-li span:nth-of-type(2) {
  width: 35%;
}

.one-li span:nth-of-type(3) {
  width: 35%;
}

.word-collections-panel {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  align-items: baseline;
}

.word-in-collections,
.word-collections-lst {
  width: 56%;
  margin: auto 10px;
}

.add-to-collection-list {
  width: 76%;
  margin: auto;
}

#new-word-coll-sel {
  width: 100%;
  padding: 4px;
  margin-top: 5px;
  border-radius: 7px;
  max-height: 160px;
  overflow: scroll;
}

.word-collections-lst b,
.word-in-collections b {
  color: #ec7863;
}

.word-in-collections .add-to-coll:hover {
  background-color: #e9e794;
}

.word-in-collections select,
.word-collections-lst select {
  width: 100%;
  padding: 4px;
  margin-top: 5px;
  border-radius: 7px;
  max-height: 160px;
  overflow: scroll;
}

.working-btn {
  color: #2f2b33;
  font-size: 1.2em;
  padding: 6px;
}

.other-actions {
  text-align: center;
}

.other-actions .other-hd {
  color: #983838;
  margin-bottom: 2px;
}


.list-of-coll-consists-of,
.list-of-coll-exist {
  border: 1px solid rgb(98, 98, 98);
  padding: 4px 6px;
  border-radius: 6px;
  background-color: white;
}

.button-act-div {
  margin-top: 14%;
  margin-left: 2px;
  margin-right: 2px;
}

.regex-and-btn-flex {
  display: flex;
}

.regex-and-btn-flex button {
  padding: 0 12px;
  margin: 2px 10px;
}

@media (min-width: 769px) {

  .word-in-collections select,
  .word-collections-lst select {
    padding: 4px 15px;
  }

  .word-collections-panel {
    align-items: center;
  }

  .button-act-div {
    margin-top: 0;
  }
    

}