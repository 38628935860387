/* css for materials */

.materials-list {
  text-align: left;
  list-style-type: disc;

}

.final-initial {
  overflow-x: scroll;
}

.final-initial table,
.final-initial th,
.final-initial td,
.final-initial tr {
  border-collapse: collapse;
  border: 1px solid rgb(112, 108, 108)
}

.final-initial td,
.final-initial th {
  padding: 6px;
}

.final-initial table {
  width: 100%;
  overflow: auto;
}