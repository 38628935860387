/* CSS FOR MEMBERSHIP VIEW */

.membership-main-header {
  text-align: center;
}

.membership-desc {
  width: 94%;
  margin: 0 auto;
  text-align: justify;
}

.possibilities-list {
  width: 80%;
  text-align: left;
  margin: 2px auto;
  list-style: square;
  color: #5d2525;
}

.before-after-purchase {
  color: gray;
  text-align: left;
}

.membership-image-scr {
  width: 100%; 
  margin: 10px auto;
  box-shadow: inset 60px 20px 10px #f8f8ff, inset -43px -45px 25px #f8f8ff;
  height: 340px;
  background: url("/static/imgs/screenshots/usr-search.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.membership-image-scr2 {
  width: 100%; 
  margin: 10px auto;
  box-shadow: inset 22px 31px 32px #f8f8ff, inset -43px -27px 25px #f8f8ff;
  height: 340px;
  background: url("/static/imgs/screenshots/usr-search2.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.selection-membership {
  display: flex;
  max-width: 800px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.membership-sel-type {
  margin: 2px;
  padding: 2px 6px;
}

.buy-btn {
  margin-top: 18px;
  cursor: pointer;
  padding: 8px 16px;
  color: #fff5e1;
  border-radius: 4px;
  border: none;
  background-color: #8997c1;
  transition: transform 0.2s;
}

.buy-btn:hover {
  transform: scale(1.1);
  background-color: #768ed4;
}

.not-must {
  text-align: center;
  color: #4e7071;
}

.on-your-choice {
  color: #81a54c;
}

@media (min-width: 869px) {
  .buy-btn {
    margin-top: 0;
  }

  .selection-membership {
    flex-direction: row;
  }
  .membership-desc {
    width: 80%;
  }
}