/* CSS FOR SUCCESSFUL PAYMENT */

.payment-container {
  width: 70%;
  margin: 20px auto 50px;
}

.payment-container div.im-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

}

.successful-basket {
  width: 50%;
} 


@media (min-width: 769px) {
  .successful-basket {
    width: 30%;
  } 
  .payment-container div.im-bt {
    align-items: center;
    flex-direction: row;
  }
}