/* css for suggest sentence view */

.sentence-cont {
  width: 90%;
  margin: 0 auto;
}

.sgst-sentence-lbl {
  color: rgb(235, 98, 18);
}

.addsentence-fieldset label {
  padding: 1%;
}

@media (min-width: 769px) {
  .sentence-cont {
    width: 70%;
  }
}