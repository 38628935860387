/* css for tickets in work page */

.ticket-in-work {
  border: 2px solid gray;
  margin: 30px auto;
  padding: 6px 4px 14px;
}

.ticket-date-created {
  color: rgb(95, 91, 91);
}

.ticket-in-work-head-status {
  width: 95%;
  margin: 14px auto;
  display: flex;
  justify-content: space-between;
}

.ticket-in-work-head-status > h3 {
  margin: 0;
}

.ticket-in-work-theme {
  color: blue;
}

.ticket-in-work-msg-body {
  margin: 8px auto;
  width: 80%;
  text-align: left;
}

.ticket-in-work-email {
  margin: 4px auto;
}

.ticket-in-work-email > span {
  color: #b39d04;
  margin-left: 4px;
}

.ticket-in-work-username {
  color: #a72228;
}

.ticket-in-work-msg-body > p {
  background-color: #f9e2d8;
  margin: 5px 0;
  padding: 6px 4px;
  border-left: 2px solid indianred;
}

.ticket-in-work-action-btns {
  display: flex;
  width: 90%;
  margin: 34px auto 10px;
  justify-content: space-between;
}
.ticket-in-work-action-btns button {
  border: none;
  padding: 10px 14px;
  cursor: pointer;
}

.ticket-in-work-del-btn {
  background-color: #ff9b9b;
  color: white;
}

.ticket-in-work-del-btn:hover {
  background-color: #f15555;
}

.ticket-in-work-throw-btn {
  margin-right: 20px;
  color: #584d4d;
}

.ticket-in-work-throw-btn:hover {
  background-color: rgb(204, 196, 196);
  color: black;
}

.ticket-in-work-solved-btn {
  background-color: #42c341;
  color: white;
}

.ticket-in-work-solved-btn:hover {
  background-color: #54d654;
}

.ticket-in-work-red {
  color: red;
}

.ticket-in-work-green {
  color: green;
}