/* css for approve_text view */

.new-txtmsg {
  display: block;
  margin: 10px auto;
}

.sgsted-txtset {
  box-sizing: border-box;
  width: 96%;
}

.tx-lvl {
  margin-left: 10px;
  margin-right: 2px;
}

.tagsLab-sgt {
  display: block;
  margin: 24px auto 0;
}

.sgstd-textAr {
  width: 100%;
}

.text-btns {
  margin: 20px auto;
}

.approve-tbtn,
.disapprove-tbtn {
  width: 45%;
}

.approve-tbtn {
  background-color: rgb(61, 238, 85);
  float: left;
}

.disapprove-tbtn {
  background-color: rgb(219, 83, 83);
  float: right;
}