/* CSS FOR ADMIN PAYMENTS VIEW */

.all-payments-ul > li:not(:first-child) {
  background-color: rgb(198, 238, 138);
  display: flex;
  border-radius: 4px;
  padding: 0px 12px;
  margin: 2px 0px;
  cursor: pointer;
  transition: transform 0.2s;
  font-weight: 600;
  color: #284973;
  justify-content: space-between;
  align-items: center;
}

.all-payments-ul > li:hover:not(:first-child) {
  transform: scale(1.05);
  background-color: rgb(200, 235, 152);
}

.clickable-payment-div {
  width: 96%;
  display: flex;
  justify-content: space-evenly;
}

.clickable-payment-div > span.eml {
  color: #27279f;
}

.clickable-payment-div> span.uid {
  color: rgb(87, 87, 87);
}

.clickable-payment-div > span.status {
  color: #de5f38;
}

.delete-payment-span {
  color: red;
  font-size: 1.2em;
  border-radius: 4px;
  padding: 4px 8px;
  transition: transform 0.2s;
}

.delete-payment-span:hover {
  background-color: rgb(255, 129, 129);
  transform: scale(1.1);
}

.payment-modal-content {
  background-color: lavender;
  width: 96%;
  padding: 25px 30px;
  margin: 50px auto;
}

.payment-data {
  text-align: left;
  width: 80%;
  margin: 14px auto;
}

.payment-data table {
  margin: 0 auto;
  border-spacing: 0.4em;
}
.greenee {
  color: rgb(38, 185, 38); 
}

.reddy {
  color: rgb(202, 69, 69);
}

.payment-data .succeeded,
span.succeeded {
  color: rgb(11, 138, 11);
}

.payment-data .pending,
span.pending {
  color: #c3b82b;
}

.payment-data .canceled,
span.canceled {
  color: rgb(171, 10, 10);
}

.payment-data .waiting_for_capture,
span.waiting_for_capture {
  color: rgb(247, 206, 57);
}

.payment-data .error-pay,
span.error-pay {
  color: rgb(247, 57, 57);
}

.sort-innp {
  display: flex;
  justify-content: space-between;
}

.refund-modal-content {
  width: 90%;
  margin: 40px auto;
  background-color: ghostwhite;
  padding: 12px 44px 30px
}


@media(min-width: 769px) {
  .payment-modal-content {
    width: 50%;
  }

  .refund-modal-content {
    width: 40%;
  }
}