/* css for approve_word view */

.word-sgtd-ins {
  width: 96%;
  margin: 20px auto;
}

.word-card-content.approve.editable {
  margin: 20px auto;
}

.btns-wsgt {
  margin: 20px auto;
}

.approve-wbtn {
  margin-right: 10px;
  background-color: rgb(47, 255, 75);
}

.approve-wbtn:hover {
  background-color: rgb(95, 255, 116); 
}

.disapprove-wbtn {
  background-color: rgb(245, 124, 87);
}

.disapprove-wbtn:hover {
  background-color: rgb(246, 137, 104);
}

@media(min-width: 769px) {
  .word-sgtd-ins {
    width: 60%;
  }
}