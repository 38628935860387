
/* css for register view */

.register-form {
  margin: 16px auto;
  width: 96%;
  background-color: #fff;
  padding: 28px;
  border: 1px solid #e3e9f9;
}

.register-form > label {
  font-weight: bold;
  float: left;
}

.register-form > div.reg-msg-invalid {
  float: right;
  color:rgb(255, 255, 255);
  border-radius: 4px;
  width: fit-content;
  padding: 0px 4px;
  background-color: rgb(241, 156, 156);
}


.register-form > div.reg-msg-valid {
  float: right;
  color:rgb(255, 255, 255);
  border-radius: 4px;
  width: fit-content;
  padding: 0px 4px;
  background-color: rgb(174, 220, 153);
}

.create-acc-btn {
  margin: 12px 24px 24px;
  border-radius: 5px;
  max-width: 146px;
}

.have-account {
  border-radius: 5px;
  background-color: #f1f1f1;
  padding: 4px;
}

.show-passes {
  display:block;
  color:gray;
  font-weight: 100 !important;
  float: none !important;
  text-align:center;
}

/* end register */

/* css for account_confirmed view */

.confirmed-msg {
  margin: 44px auto;
}

/* end account_confirmed */

/* css for register_successful view */

.reg-succmsg {
  margin: 28px auto;
}


/* end register_successful */

@media (min-width: 769px) {
  .register-form {
    width: 55%;
  }
}