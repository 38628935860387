
.battle-application-form {
    margin: 10px 2px;
    text-align: left;
    border: 1px solid #887878;
    background-color: #eeebeb;
    padding: 12px 20px;
}

.battle-application-form .create-application-btn {
    padding: 8px 22px;
    background-color: #e07373;
    border: 1px solid #791c1c;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    scale: 1;
    transition: transform .2s;
}

.battle-application-form label {
    display: block;
    margin: 6px auto;
    font-weight: bold;
    width: 100%;
}

.battle-application-form label:not(:first-child) {
    margin-top: 10px;
}

.battle-application-form input,
.battle-application-form select {
    width: 100%;
    padding: 4px 10px;
}

.battle-words-panel {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.words-dictionary-cut {
    max-height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
    background-color: #ffffff;
    border-left: 1px solid #dbdbdb;
}

.words-added-table {
    height: 320px;
    background-color: white;
    border-right: 1px solid #e4e2e2;
}

.words-dictionary-table {
    border-collapse: collapse;
}

.words-dictionary-table td {

    white-space: nowrap;
}

.words-dictionary-table tr.selected,
.words-added-table tr.selected {
    background-color: rgb(201, 210, 201);
}

.words-dictionary-table tr,
.words-added-table tr {
    width: 100%;
    min-width: 100%;
    display: block;
}

.icon-btl {
    width: 35px;
    height: 35px;
}

.flex-for-icon {
    display: flex;
}
.flex-for-icon img {
    margin-right: 8px;
}

.btn-flames {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.btn-flames button {
    display: inline;
}

.flames {
    width: 30px;
    height: 30px;
    display: inline;
    margin-left: 6px;
}


.words-added-table,
.words-dictionary {
    padding: 10px;
    display: inline-block;
    
}

.battle-hr {
    margin: 20px 0px;
}

.create-application-btn:hover {
    transform: scale(1.05);
}

.word-in-d {
    display: flex;
}

.words-table-count {
    display: flex;
    justify-content: space-between;
}

.words-added-table,
.words-dictionary  {
    max-width: 45%;
    min-width: 45%;
    text-overflow: ellipsis;
}

.words-added-table {
    overflow-y: scroll;
    overflow-x: hidden;
    border-collapse: collapse;
    white-space: nowrap;
}

#add-word-btn {
    min-width: 34px;
}

#here-is-empty {
    white-space: pre-wrap;
}

@media (min-width: 769px) {
    .battle-application-form {
        max-width: 500px;
        margin: 10px auto;
    }
    
  
}