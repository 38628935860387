/* css for one-ticket view */

.ticket-date {
  margin-bottom: 30px;
}

.one-ticket-body {
  margin: 0 auto;
  border: 2px solid rgb(180, 180, 180);
  text-align: left;
  padding: 26px 0 16px;
}

.one-ticket-head-status {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

.one-ticket-theme > h3 > span {
  color: blue;
}

.one-ticket-theme h3,
.one-ticket-status p,
.ticketmsg-admin p {
  padding: 0;
  margin: 0;
}

.one-ticket-red {
  color: red;
}

.one-ticket-green {
  color: green;
}

.one-ticket-msg-body {
  display: flex;
  width: 80%;
  margin: 20px auto 10px;
  flex-direction: column;
}

.one-ticket-msg-body > p {
  margin: 5px 0;
  padding: 6px;
  border-left: 3px solid gray;
  margin-bottom: 20px;
  background-color: rgb(250, 239, 226);
}

.one-ticket-action-btns {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.one-ticket-action-btns button:first-child {
  cursor: pointer;
  background-color: #f98282;
  padding: 10px;
  border: none;
  color: white;
}

.one-ticket-action-btns button:first-child:hover {
  background-color: rgb(240, 46, 46);
}

.one-ticket-action-btns button:last-child {
  background-color: rgb(38, 182, 38);
  color: white;
  padding: 10px;
  cursor: pointer;
  border: none;
}

.one-ticket-action-btns button:last-child:hover {
  background-color: rgb(23, 133, 23);
}

.one-ticket-rm-and-block-btn {
  cursor: pointer;
  margin-top: 24px;
  background-color: brown;
  padding: 10px;
  border: none;
  color: white;
}

.one-ticket-rm-and-block-btn:hover {
  background-color: rgb(192, 37, 37); 
}