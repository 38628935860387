/* css for users view */

.search-users {
  background-color: #e6f3e2;
  padding: 20px;
  margin: 20px auto;
}

.search-for-users {
  display: flex;
  margin: 14px 0;
}

.user-found {
  display: flex;
  cursor: pointer;
  max-height: 122px;
  justify-content: space-evenly;
  background-color: aliceblue;
  margin: 10px 0;
  padding: 16px 6px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px #c3c3c3;
  transition: transform 0.2s;
}

.user-found .reg-dt {
  margin-top: 4px;
  color: #5f5f5f;
}

.user-found .about-user .u-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.user-found.u-member,
.registered-user.u-member {
  background-color: #f8f4c6;
}

.user-found:hover {
  transform: scale(1.03);
}

.user-found img.belt {
  width: 18%;
}

.user-found img.membership {
  width: 18%;
}

.about-user .u-info {
  width: 80%;
}

.about-user {
  width: 64%;
  display: flex;
}

.about-user b {
  margin-bottom: 10px;
  margin-top: 6px;
}

.search-for-users > button:hover {
  background-color: #cad3ef;
}

.users-panel {
  display: flex; 
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #c0daecbf;
  padding: 10px;
  min-height: 180px;
}

.users-panel h4 {
  margin: 6px auto;
  color: #403b3b;
}

.last-ten-users {
  display: flex;
  overflow-x: scroll;
  padding: 6px 0;
}

.wrap-total-two {
  padding: 6px 0;
}

.total-users-wrapper {
  width: 100%;
}

.last-ten-wrapper {
  width: 100%;
}

.total-users {
  width: 190px;
  margin: 0 auto;
  align-items: center;
  background-color: #c7e098;
  box-shadow: 2px 2px 4px #afafaf;
  border-radius: 10px;
  padding: 20px;
  min-height: 100px;
  font-size: 3em;
  color: #fbfbfb;
  font-weight: 700;
  display: block;
  text-align: center;
}

.registered-user {
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  min-width: 190px;
  background-color: #e6eafa;
  min-height: 100px;
  margin: 0 5px 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #afafaf;
  padding: 8px 0;
  transition: transform 0.2s;
}

.registered-user:hover {
  transform: scale(1.03);
}

.last-ten-users:last-child::after {
  content: "";
  display: block;
  min-width: 10px;
}

.registered-user div:first-child {
  width: 35%;
  display: flex;
  align-items: center;
}

.reg-user-lvl-name {
  align-items: baseline;
  display: flex;
  align-self: center;
  flex-direction: column;
  overflow: hidden;
  max-width: 50%;
}

.registered-user .lvl {
  margin-top: 6px;
  color: #2b4344;
}

.registered-user img {
  width: 100%;
}

.not-found-users {
  font-weight: bold;
  color: #484545;
}

.paginator {
  background-color: #cee2f0;
  margin: 0 auto;
  padding: 6px;
  border-radius: 10px;
}

.paginator .page-num.current {
  cursor: not-allowed;
  color: #505b75;
  background-color: ghostwhite;
}

.paginator .page-num {
  display: inline-block;
  color: #ffffff;
  padding: 5px 8px;
  background-color: #aaaae4;;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.paginator .page-num:hover {
  transform: scale(1.1);
}


@media (min-width: 769px) {
  .users-panel {
    flex-direction: row;
  }
  .last-ten-wrapper {
    width: 65%;
  } 
}

@media (max-width: 969px) {
  .users-panel {
    flex-direction: column;
  }
  .last-ten-wrapper {
    width: 100%;
  } 
}