/* css for choose_help view */

.container-help {
  margin: 0 auto;
  width: 96%;
}

.help-disclaimer {
  text-indent: 0;
  text-align: left;
  padding-left: 10px;
  border-left: 3px solid rgb(241, 61, 61);
}

.attentionn {
  margin-top: 30px;
}

.choose-help {
  margin: 0 auto;
}

.choose-help li {
  margin: 1px;
  text-shadow: 1px 1px rgb(163, 142, 142);
  background-color: rgb(156, 197, 247);
  border-radius: 5px;
}

.choose-help li:last-child {
  text-shadow: none;
  margin-top: 18px;
  background-color: rgb(196, 175, 255);
}

.choose-help li:last-child:hover {
  background-color: rgb(204, 198, 255);
}

.choose-help li:hover {
  background-color: rgb(170, 206, 250);
}

.choose-help a {
  padding: 0.6em;
  color: white;
  font-size: 1.1em;
  text-decoration: none;
  display: block;
}

/* status bar */
.suggestion-bar-header h4 {
  text-shadow: 1px 1px 2px white;
  border-bottom: 1px solid rgb(135, 125, 170);
  padding: 5px 0;
  margin: 0;
  background-image: linear-gradient(to top, rgb(120, 169, 230), rgb(174, 194, 238));
}

.status-suggestion-bar {
  background-image: linear-gradient(to top, rgb(172, 199, 241), rgb(211, 209, 209));
  border-bottom: 1px solid rgb(146, 122, 255);
  box-shadow: 1px 1px 8px rgb(146, 122, 255);
  margin-top: -24px;
}

.help-span {
  padding: 0 10px;
  border-left: 1px solid rgb(146, 122, 255);
}
.help-span:last-child {
  border-right: 1px solid rgb(146, 122, 255);
}

.num-sugg-status {
  color:red;
}

@media (min-width: 769px) {

  .help-span {
    padding: 0px 30px 0px 30px;
  }

  .help-disclaimer {
    width: 70%;
    margin: 5% auto;
  }

  .status-suggestion-bar {
    top: 0;
    left: 0;
    margin-top: 0;
  }

  .choose-help {
    width: 56%;
  }
}
