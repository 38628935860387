
/* css for choose_trainings view */

.tr-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 15px;
  margin: 6px 0px;
}

.tr-item.not-av {
  background-color: #d8d8d8e3 !important;
}

.tr-item.not-av a {
  opacity: 0.55;
  text-shadow: none;
}

.tr-item > span {
  display: block;
  text-align: left;
  padding: 4px 10px;
}

.list-of-translations {
  display: block;
  width: 90%;
  margin: 0 auto;
}

.list-of-translations div {
  cursor: pointer;
  background: rgb(253, 238, 213);
  border: 1px solid #c0af4d;
}

.list-of-translations div a {
  color: rgb(59, 94, 129);
}

.list-of-translations div:hover {
  box-shadow: 
  inset 12px 12px 25px ghostwhite,
  inset -12px -12px 25px ghostwhite;
}

.list-of-translations div:nth-of-type(1) {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.list-of-translations div:last-child {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}


.training-closed {
  width: 50px;
  height: 50px;
}

.tr-desc {
  color: gray;
  display: block;
  margin-top: 6px;
  color: #a08989;
}


@media (min-width: 769px) {

  .tr-item {
    position: relative;
    margin: 0px;
  }

  .training-closed {
    position: absolute;
    position: absolute;
    right: 16px;
  }

  .tr-item span {
    text-align: center;
  }

  .tr-heading {
    margin: 0;
  }

  #transl-item1 {
    border-top-left-radius: 20px; 
    border-left: none;
    border-top: none;
  }
  
  #transl-item2 {
    border-top: none;
  }
  
  #transl-item3 {
    border-top: none;
    border-right: none;
    border-top-right-radius: 20px;
  }

  #transl-item4 {
    border-bottom: none;
    border-left: none; 
    border-bottom-left-radius: 20px;
  }
  
  #transl-item5 {
    border-bottom: none;
  }
  
  #transl-item6 {
    border-bottom: none;
    border-right: none;
    border-bottom-right-radius: 20px;
  }

  #transl-item6 {
    border-bottom-left-radius: 0;
  }

  .list-of-translations div a {
    display: block;
    color: #ffffff;
    font-size: 1.1em;
    border-radius: 6px;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 10px;
    background-color: #afb8e4;
    margin-top: 40%;
  }

  .list-of-translations {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    margin-top: 16px;
    width: fit-content;
  }

  .list-of-translations div {
    height: 220px;
    width: 220px;
    border: 1px solid #ffe1aa;
    box-shadow:
    2px 2px 15px rgb(221, 208, 191);
  }

  .list-of-translations div:nth-of-type(odd) {
    background-color: #fff3df;
  }

  .list-of-translations div:nth-of-type(even) {
    background-color: #e1e9f5;
  }

  .list-of-translations div:nth-of-type(3),
  .list-of-translations div:nth-of-type(1) {
    border-radius: 0;
  }

}