
/* css for direct and reversed translations view */

.container {
  width: 96%;
  margin: 0 auto;
}

.training-container,
.training-results,
.all-words-learned,
.all-coll-words-learned {
  display: none;
}

.progress-info-label {
  font-weight: bold;
  font-size: 1.07em;
  color: #383333;
}

.word-card {
  width: 90%;
  padding-bottom: 10px;
  background-color: rgb(255, 240, 235);
  border-radius: 20px;
  border: solid 1px #c7c7c7;
  margin: 24px auto;
  box-shadow: 2px 2px 6px rgb(195, 195, 195)
}

.ans-transcr {
  color: rgb(221, 114, 65);
}

.card-main-word {
  background-color: #ded5de;
  color: #545152;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 2.24em;
  font-family: Tahoma, Arial, "STXihei", "华文细黑", "Microsoft YaHei", "微软雅黑", sans-serif;
}

.card-main-word-transcr {
  font-size: 1.3em;
  display: block;
  margin-bottom: 12px;
  color: rgb(204, 131, 97);
  text-shadow: 1px 1px rgb(255, 232, 202);
}

.answers-ol {
  width: 70%;
  margin: 0 auto;
  padding: 0;
}

.answers-ol li:not(.wrong-answer):not(.right-answer):hover {
  background-color: #b8c9e0;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(187, 193, 207);
}

.wrong-answer {
  background-color: #f37e7e;
  color: #fffbfb !important;
}

.right-answer {
  background-color: #77cc77;
  color: #f6fff7 !important;
}

.wrong-answer .ans-transcr,
.right-answer .ans-transcr {
  color: rgb(82, 79, 78);
}

.answers-ol li {
  color: rgb(72, 72, 72);
  font-size: 1.1em;
  font-weight: 700;
  border-radius: 20px;
  padding: 5px 0px 5px 0px;
  margin: 7px 0px 7px 0px;
  cursor: pointer;
}

.answers-ol.reversed li {
  font-size: 1.34em;
}

.card-hr-train {
  margin-top: 1%;
  margin-bottom: 22px;
  width: 94%;
  box-shadow: 0px 1px 2px #4641464f;
}

.next-word-btn {
  display: none;
  padding: 8px 15px;
  border-radius: 6px;
  color: white;
  border: none;
  background-color: #a0a6c5;
  margin: 26px auto 20px;
  cursor: pointer;
}

.next-word-btn:hover {
  background-color: #7d86b3;
}

.training-ended {
  font-size: 1.7em;
  color: #84b552;
  margin-top: 12px;
  font-weight: 600;
}

.results-message {
  font-size: 1.1em;
  color: #292828;
}

.collection-name-message {
  font-size: 1.06em;
  color: #584d58;
}

.collection-inpt-msg {
  font-weight: bold;
  margin: 10px;
}

.coll-name-selection {
  margin: 16px 0;
}

.starting-info-msg {
  font-size: 1.08em;
}
 
.despite-ofstatus {
  margin: 0 16px;
}

.despite-ofstatus > input {
  margin: 0 3px 0 0;
  transform: scale(1.1);
}

.despite-ofstatus > label {
  font-size: 1.05em;
}

.icofont-audio.train-au {
  display: inline-block;
  color: #635d5d;
  margin-left: 9px;
  transition: transform 0.3s;
}

.icofont-audio.train-au:hover {
  transform: scale(1.4);
}

@media (min-width: 769px) {
  .container {
    width: 70%;
    max-width: 1036px;
  }
  .word-card {
    width: 70%;
    max-width: 600px;
  }
  .despite-ofstatus {
    display: inline-block;
  }
}