/* css for login view*/

.heart-login {
  margin-left: 4px;
  width: 24px;
}

.login-form {
  width: 90%;
  margin: 30px auto;
  background: #fff;
  padding: 26px 30px 20px;
  border: 1px solid #ddddff;
}

.login-form > label:not(:last-of-type) {
  font-weight: bold;
  font-size: 1.15em;
  text-align: left;
  display: block;
}

.remember-checkbox {
  margin-left: 4px;
  transform: scale(1.1);
}

.forgot-psw {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 4px 0;
  margin: 26px auto;
}

.loading-gif {
  margin: 0 10px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  background-color: white;
}

.twoFactor-content {
  max-width: 600px;
  width: 50%;
  margin: 10% auto;
  background-color: #e8e6e6;
  padding: 28px;
}

.successPlate-content {
  color: green;
  border-radius: 9px;
  padding: 14px 44px 9px 44px;
  background-color: white;
  width: fit-content;
  margin: 16% 52%;
  border: 2px solid #1fbd1f;
  box-shadow: 2px 3px 5px #7d7373;
}

@media (min-width: 981px) {
  .login-form {
    width: 48%;
  }
}

@media (min-width: 761px) and (max-width: 980px) {
  .login-form {
    width: 60%;
  }
}