.background-ofmodal.word-descr-card {
  background-color: rgba(6, 6, 7, 0.02);
}
  
.word-card-content {
  background-color: #fffee8;
  box-shadow: 4px 5px 4px #dadada;
  border-radius: 14px;
  width: 92%;
  margin: 80px auto;
  padding: 10px 10px;
  padding-bottom: 30px;
  text-align: left;
}

.word-card-wr-tr {
  display: flex;
  font-size: 1.20em;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;

}

.word-card-wr-tr.edit {
  font-size: 1.64em;
}


.word-card-word {
  font-weight: bold;
  font-size: 1.57em;
  color: #ec5d5d;
  margin-right: 16px;
  /* font-family: 'Noto Serif SC', serif; */
}

.word-card-transcription {
  font-weight: bold;
  color: #6c2c77;
  font-size: 1.6em
}

.word-card-translations {
  font-size: 1.20em;
  margin-left: 10px;
}

.word-card-translations li {
  /* padding: 0 10px 0 10px; */
  padding-bottom: 2px;
  padding-left: 10px;
  color: #2d2b2b;
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: -0.6px;
}

.word-card-translations li:not(.editable):hover {
  background-color: #98d9e9;
}

.card-advice {
  max-width: 90%;
  display: block;
  margin: auto;
  font-size: 0.84em;
  color: #2b2a2a;
  margin-bottom: 6px;
}

.word-card-act-btns {
  display: flex;
  justify-content: center;
  padding: 10px 4px 4px;
}

/* css for admin addword */

.word-card-word.editable,
.word-card-transcription.editable {
  display: inline-block;
  margin: 2px 2px;
  padding: 6px 12px;
  font-size: 0.8em;
  border-radius: 4px;
  border: none;
  min-width: 100px;
  border: 1px solid #bbbbbb;
}


.word-card-word.editable {
  color: #ea1a1a;
}

.word-card-transcription.editable {
  color: #6c2c77;
}

.word-card-translations li.editable {
  margin: 4px 0 0;
}

.word-card-translations li.editable > input {
  border: none;
  width: 99%;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  border: 1px solid #c3c3c3;
}

.word-card-au-wrap {
  padding: 11px 0 0;
  color: #986363;
  font-size: 1.3em;
}

.change-au-btn {
  margin-left: 10px;
  display: inline;
  font-size: .86em;
  padding: 4px 11px;
  border-radius: 8px;
  background-color: #ffeacf;
  border: 1px solid #f3cece;
  color: #9a4141;
  transition: transform 0.2s;
  margin-top: 10px;
}

.change-au-btn:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #fff1e0;
}

.icofont-audio:before {
  color: black;
}

.word-card-au-wrap input {
  opacity: 0.7;
  font-size: 0.84em;
}

.word-card-au-wrap input:focus {
  opacity: 1;
}

.word-card-au-wrap span {
  margin-left: 6px;
  display: inline-block;
  opacity: 0.7;
  font-size: 1.2em;
  transition-property: transform;
  transition-duration: 0.3s;
}

.word-card-au-wrap span:hover {
  opacity: 1;
  cursor: pointer;
  transform: scale(1.4);
}

.add-alttransl-btn-li {
  margin-right: 20px;
  padding: 2px 12px;
  border-radius: 6px;
  border: none;
  background-color: #c9d2be;
  color: white;
  transition: all 0.2s;
}

.add-alttransl-btn-li:hover {
  transform: scale(1.08);
  background-color: #b0d582;
  cursor: pointer;
}

.save-changes-btn-li {
  margin-top: 0;
  margin-right: 0;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  background-color: #a4d268;
  color: #fff;
  transition: transform 0.2s;
}

.save-changes-btn-li:hover {
  cursor: pointer;
  transform: scale(1.06);
  background-color: #a8cc3a;
}

.wcard-bt-wrap {
  display: flex;
  margin-top: 10px;
  font-size: 0.66em;
  justify-content: space-between;
}

.add-new-wrd {
  margin-top: 18px;
  padding: 14px 35px;
}

.add-new-wrd:hover {
  background-color: #8fa3e4;
}

.search-div {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0 0;
}

.search-results {
  margin-bottom: 15px;
}

.search-results > b {
  display: block;
  color: #48578c;
  margin: 10px auto 5px;
}

.word-cards {
  max-height: 310px;
  border-radius: 10px;
  border: 1px solid #d7dade;
  background-color: rgb(249, 246, 217);
  overflow: auto;
}

.no-coincedence {
  color: rgb(234, 201, 181);
}

.find-word-btn {
  padding: 12px 50px;
  margin: 0 0 20px;
}

/* MODAL styles */
.modal-content {
  background-color: #fefefe;
  margin: 8% auto; 
  border-radius: 10px;
  border: 1px solid #888;
  width: 96%;
}

.modal-word-heading {
  margin: 4px auto 8px;
  max-width: 80%;
}

.modal-word-transcription {
  font-size: 1.1em;
  color: blue;
}

.alternative-translations ol {
  font-size: 1.15em;
  text-align: left;
  width: 80%;
  margin: 18px auto 20px;
}

.alternative-transl-inpt {
  width: 80% !important;
  padding: 11px 16px !important; 
  margin: 1px 0px 18px !important;
  border-radius: 9px !important;
}

.attention {
  text-align: left;
  width: 80%;
  margin: 10px auto 30px;
}

.attention i {
  display: block;
  padding-left: 10px;
  border-left: solid red 2px;
}

.modal-two-btn-actions {
  display: flex;
  justify-content: space-between;
  margin: 16px auto;
}

.modal-two-btn-actions > button {
  padding: 10px 24px;
  border-radius: 5px;
}

.add-word-btn {
  background-color: rgb(162, 218, 162);
}

.add-word-btn:hover {
  background-color: rgb(125, 218, 125);
}

.delete-word-btn {
  background-color: rgb(235, 106, 106);
}

.delete-word-btn:hover {
  background-color: rgb(236, 81, 81);
}

/* search word cards */
.cards-ul {
  padding: 3%;
}

.cards-translations {
  padding-left: 8px;
}

.cards-translations li {
  width: fit-content;
  color: #444040;
  margin-left: 12%;
  padding: 5px;
  text-align: left;
}

.one-card {
  border-radius: 5px;
  margin: 4px 0;
  background-color: rgb(204, 234, 191);
}

.one-card:nth-child(odd) {
  background-color: rgb(213, 230, 236);
}

.word-itself {
  padding: 15px 1px 1px;
  margin: 0;
  font-size: 1.5em;
  color: #484545;
}

.word-transcription {
  font-size: 1.06em;
  color: rgb(68, 98, 119);
}

.one-card-two-btns {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 10px auto 0;
}

.del-word-btn,
.edit-alt-transl-btn {
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 20px;
}

.del-word-btn {
  background-color: rgb(245, 136, 136);
  border: solid 1px black;
  color: rgb(255, 240, 240);
}

.del-word-btn:hover {
  background-color: rgb(255, 104, 104);
}

.edit-alt-transl-btn {
  color: #495a3a;
  background-color: #bbff98;
  border: solid 1px #84b557;
}
.edit-alt-transl-btn:hover {
  background-color: #96dd61;
}

#wrd-audio {
  margin-top: 4px;
}

@media (min-width: 769px) {

  .word-card-content {
    width: 50%;
    max-width: 650px;
    padding: 20px 24px;
  }

  .word-card-translations {
    /* font-size: 1.64em; */
    margin-left: 10px;
  }

  .search-div {
    width: 20%;
    right: 0;
    top: 0;
    margin: 14px 14px 0 0;
    position: fixed;
  }

  /* .add-alttransl-btn-li {
    margin-right: 16%;
  } */

  .modal-content {
    width: 40%;
  }

  .one-card-two-btn {
    width: 86%;
    margin: 10px auto 0;
  }

  .del-word-btn,
  .edit-alt-transl-btn {
    padding: 4px 26px;
  }

  /* .save-changes-btn-li {
    margin-right: 16%;
  } */

  .change-au-btn {
    margin-top: 0;
  }

  #wrd-audio {
    margin-top: 0;
  }
}
