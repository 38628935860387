/* css for chinese cards view */

.training {
  display: none;
}

/* entire container, keeps perspective */

.flip-container {
  display: inline-block;
  perspective: 500px;
  cursor: pointer;
  margin: 10px;
}

.flip-container.no-flip {
  cursor: not-allowed;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back,
.results-back {
  background-color: rgb(159, 245, 159);
  overflow: hidden;
	transform: rotateY(180deg);
}

	/* flip the pane when hovered */  
  .flip-container.flip .flipper {
		transform: rotateY(180deg);
  }

.flip-container, 
.front, 
.back,
.results-front,
.results-back {
  border-radius: 20px;
	width: 220px;
	height: 120px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
}

/* hide back of pane during swap */
.front, .back,
.results-front, .results-back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

.six-cards {
  margin: 0 auto;
}

.front h2 {
  padding-top: 10%;
  margin: 0;
  font-size: 2.4em;
  color: rgb(206, 97, 97);
  text-shadow: 1px 1px 1px rgb(97, 94, 94);
}

.front span {
  margin: 0;
  text-shadow: 1px 1px rgb(214, 206, 206);
}

.back h2 {
  padding-top: 18%;
  padding-bottom: 10%;
  white-space: nowrap;
  margin: 2%;
  color: rgb(88, 83, 83);
  text-shadow: 1px 1px 4px rgb(255, 255, 255);
  overflow: auto;
}

.back ::-webkit-scrollbar  {
  height: 4px;
}

.flip-container:nth-child(odd) .front,
.flip-container:nth-child(odd) .results-front {
  background-color:rgb(255, 237, 215);
  box-shadow: 2px 2px 12px rgb(241, 221, 105);
  border: 1px solid rgb(223, 218, 218);
}

.flip-container:nth-child(odd) .back,
.flip-container:nth-child(odd) .results-back {
  background-color:rgb(255, 221, 211);
  box-shadow: 2px 2px 12px rgb(241, 221, 105);
  border: 1px solid rgb(223, 218, 218);
}

.flip-container:nth-child(even) .front,
.flip-container:nth-child(even) .results-front {
  background-color:rgb(248, 243, 165);
  box-shadow: 2px 2px 12px rgb(241, 221, 105);
  border: 1px solid rgb(223, 218, 218);
}

.flip-container:nth-child(even) .back,
.flip-container:nth-child(even) .results-back {
  background-color:rgb(169, 241, 141);
  box-shadow: 2px 2px 12px rgb(241, 221, 105);
  border: 1px solid rgb(223, 218, 218);
}

.irmbr {
  display: block;
  margin: 0 auto;
  min-width: fit-content;
  width: 18%;
  font-size: 1.2em;
  border-radius: 14px;
}

.irmbr:hover {
  background-color: rgb(146, 238, 60);
}

.two-sided-div {
  display: inline-block;
  width: 96%;
  margin-bottom: 8%;
  margin-top: 5%;
  margin: 0 auto;
}

.step2-left-div {
  padding: 2%;
  width: 45%;
  float: left;
  border-right: 8px solid rgb(206, 70, 46);
}

.step2-right-div {
  padding: 0 2%;
  width: 45%;
  float: right;
}

.card-answers-ul {
  text-align: left;
  text-shadow: 2px 2px rgb(228, 183, 183);
  font-size: 1.45em;
  font-weight: 500;
  margin: 0;
  padding: 0 12%;
}

.card-answers-ul li {
  padding: 4px 20px;
  text-align: left;
}

.card-answers-ul label:hover {
  cursor: pointer;
}

.card-answers-ul input {
  margin-right: 8%;
}

.right-answer-card {
  border-radius: 6px;
  background-color: lightgreen;
}

.wrong-answer-card {
  border-radius: 6px;
  color: snow;
  text-shadow: 2px 2px red;
  background-color: #FF7272;
}

.results-front.wrong,
.results-back.wrong {
  border: 2px solid red !important;
  box-shadow: 2px 2px 5px red, -2px -2px 5px red !important;
  opacity: 0.5 !important;
}

.results-front.wrong,
.results-back.wrong {
  border: 2px solid red !important;
  opacity: 0.5 !important;
}

.results-front.right
.results-back.right {
  border: 2px solid green !important;
  box-shadow: 2px 2px 5px green, -2px -2px 5px green !important;
}

.results-front.right,
.results-back.right {
  border: 2px solid green !important;
  box-shadow: 2px 2px 5px green, -2px -2px 5px green !important;
}

.conclusion-msg {
  display: block;
  font-size: 1.1em;
}

@media (min-width: 769px) {

  .six-cards {
    padding-bottom: 14px;
    width: 750px;
    margin: 24px auto;
  }

  .two-sided-div {
    display: flex;
    width: 80%;
    margin-bottom: 8%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .card-answers-ul {
    text-shadow: 2px 2px rgb(228, 183, 183);
    list-style: none;
    font-size: 1.45em;
    font-weight: 500;
    margin: 0;
    padding: 0 14%;
  }

  .training-results {
    margin-bottom: 20px;
  }
}