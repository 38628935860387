/* CSS FOR ERROR PAYMENT */

.first-way,
.second-way,
.some-way {
  margin-left: 5%;
}

.way-a,
.way-b {
  margin-left: 2%;
  margin-top: 10px;
}

.payment-err-img {
  width: 30%;
}

.some-way {
  font-weight: 600;
}