/* css for add news */

.edit-news-workspace {
  display: none;
}

.add-news-txt-area,
.edit-news-txt-area {
  width: 100%;
  margin: 20px auto;
}

.edit-news-action-butons {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 6px auto;
}

.edit-news-search-by-id {
  margin: 0 auto;
  width: 96%;
}

.edit-news-workspace h1 {
  padding-top: 1%;
}

.edit-news-info-msg {
  display: block;
  margin: 20px auto 10px;
}

.news-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-list li:nth-child(odd) {
  background-color: #cad7f3;
  border-radius: 4px;
}

.news-id,
.news-head-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-id {
  width: 20%;
}

.news-head-body {
  width: 70%;
}

.news-head-body span:first-child {
  font-weight: 600;
  color: rgb(39, 39, 39);
}

.delete-new-span  {
  transform: scale(1.5);
  border-radius: 10px;
  padding: 4px 8px;
  margin-right: 5px;
  color: red;
  cursor: pointer;
}

.delete-new-span:hover {
  background-color: rgb(221, 192, 192);
}

/* deletion modal */
.deletion-news-modal-item-id,
.deletion-news-modal-item-heading {
  margin: 10px auto;
  display: block;
}

.deletion-news-modal-item-heading {
  color: red;
  margin-bottom: 20px;
}

.deletion-news-action-btns {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 18px auto 8px;
}

.deletion-news-action-btns > button {
  margin: 10px 0px;
  padding: 10px 28px;
  border-radius: 5px;
}

.deletion-news-action-btns button:first-child {
  padding: 6px 10px;
  background-color: rgb(238, 136, 136);
}

.deletion-news-action-btns button:last-child {
  padding: 8px 12px;
  background-color: rgb(173, 187, 219);
}

.deletion-news-action-btns button:first-child:hover {
  background-color: rgb(238, 117, 117);
}

.deletion-news-action-btns button:last-child:hover {
  background-color: rgb(192, 205, 235);
}

.deletion-news-modal {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 6px;
  border: 1px solid #888;
  width: 96%;
}

.deletion-news-modal h3 {
  margin-top: -10px;
  color: #a94e4e;
}

.pre-view-news {
  width: 100%;
  margin: auto;
  margin-top: 5%;
}

.sort-label {
  float: left;
}

@media(min-width: 769px) {
  .edit-news-search-by-id {
    margin: 0 auto;
    width: 60%;
  }

  .deletion-news-modal {
    width: 34%;
  }
}