/* css for one collection view */

.add-all-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-all-bt button {
  border: none;
  color: white;
  padding: 8px 12px;
  background: #a1d07e;
  width: fit-content;
}

.add-all-bt button:hover {
  background: #80ab5f; 
  cursor: pointer;
}

.coll-description {
  background: #fff5a3;
  padding: 10px 30px;
  border-radius: 14px;
  margin-bottom: 16px;
  text-align: center;
  color: #504c4c;
}

.disabled-add {
  background: #cde0bf !important;
  cursor: not-allowed !important;
}