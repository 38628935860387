/* css for one ticket view in userprofile */

.user-ticketmsg {
  margin: 15px auto 0;
  border-left: 3px solid gray;
  text-align: left;
  padding: 3px 10px 3px;
  background-color: rgb(242, 235, 245);
  max-width: 880px;
}

.ticketlabel-msg {
  display: block;
  margin-top: 20px;
}

.user-ticketmsg > p {
  margin: 0;
}

@media (min-width: 769px) {
  .user-ticketmsg {
    width: 78%;
  }
}