
/* css for add sentence */

.lvl {
  margin-left: 4px;
  margin-right: 2px;
}

.all-sentences-info-msg {
  display: block;
  margin: 10px auto 10px;
}

.all-sentences-panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.all-sentences-panel > button {
  margin: 0;
  padding: 8px 14px;
}

.add-new-sentence-btn {
  background-color: rgb(180, 221, 103); 
  color: green;
  border-radius: 4px;
  padding: 12px;
  margin: 20px 0 0 0;
}

.add-new-sentence-btn:hover {
  background-color: rgb(215, 248, 153); 
}

.all-sentences-table {
  width: 100%;
  border-collapse: collapse;
}

.all-sentences-table tr:not(:last-child) {
  border-bottom: 1px dotted rgb(190, 190, 190);
}

.all-sentences-table tr > td {
  max-width: 65px;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.all-sentences-table tr > td:last-child {
  max-width: 16px;
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 15px 45px 25px;
  border: 1px solid #888;
  width: 64%;
}

.modal-sentence-info-msg {
  margin-bottom: 8px;
  display: block;
}

.modal-sentence-text {
  width: 100%;
  margin: 20px auto;
}

.modal-sentence-act-btn:hover {
  background-color: yellowgreen;
}

.delete-sentence-span {
  color: red;
  padding: 2px 8px;
  border-radius: 6px; 
  cursor: pointer;
}

.delete-sentence-span:hover {
  background-color: #f1acac;
}

.editable-row {
  cursor: pointer;
}

.editable-row:hover {
  background-color: #e7e7e7;
}

/* for modal */

.modal-head {
  margin-top: 0;
}

.modal-remove-act-btns {
  display: flex;
  justify-content: space-between;
}

.modal-remove-act-btns button {
  min-width: 80px;
  border-radius: 5px;
}

.modal-remove-act-btns button:first-child {
  background-color: rgb(225, 103, 103);
}

.modal-remove-act-btns button:first-child:hover {
  background-color: rgb(221, 76, 76);
}

.modal-remove-act-btns button:last-child {
  background-color: #989393;
}

.modal-remove-act-btns button:last-child:hover {
  background-color: #bdb4b4;
}

.modal-remove-stc-text {
  color: red;
  width: 66%;
  margin: 10px auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}