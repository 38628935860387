/* css for all pending tickets view */

.resolved-num-head > span,
.unresolved-num-head > span {
  padding: 2px 6px;
  border-radius: 5px;
  margin: 0 10px;
}

.resolved-num-head {
  color: rgb(17, 156, 17);
}

.resolved-num-head a,
.unresolved-num-head a {
  cursor: pointer;
}

.search-ticket-by-id {
  display: flex;
  align-content: center;
}

.search-ticket-by-id input {
  margin: 0;
}

.search-ticket-by-id button {
  padding: 2px 36px;
  border: none;
  color: #444e56;
}

.search-ticket-by-id button:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.resolved-num-head > span {
  color: green;
  padding: 2px 6px;
  border-radius: 5px;
  margin: 0 10px;
  background-color: greenyellow;
}

.unresolved-num-head {
  color: #dc6d6d !important;
}

.unresolved-num-head > span {
  color: rgb(255, 255, 255);
  background-color: rgb(255, 109, 104);
}

.pending-ticket-id {
  position: relative;
  padding: 10px;
  background-color: beige;
  border: 2px solid #a2a2a2;
}


.pending-ticket-id > b {
  color: grey;
  position: absolute;
  top: 30%;
  left: 10px;
}

.pending-ticket-id > h3 {
  margin: 4px 0;
  padding: 0;
}

.pending-ticket-body {
  text-align: left;
  padding: 18px 26px;
  background-color:#f7f7ff;
  border: 2px solid #a2a2a2;
  border-top: none;
}

.pending-ticket-theme {
  display: flex;
  justify-content: space-between;
}

.pending-ticket-theme > i {
  color: #5d5757;
}

.pending-ticket-message span.ticket-from {
  color: #295d01;
}

.pending-ticket-message > p {
  background-color: #f5d7d7;
  padding: 10px 4px;
  border-radius: 2px;
  border-left: 2px solid #b15555;
}

.pending-ticket-status-url {
  display: flex;
  justify-content: space-between;
}

.pending-green-ticket {
  color: green;
}

.pending-red-ticket {
  color: red;
}

.page-div-btns {
  margin-top: 16px;
  display: flex;
  justify-content: space-around;
}

.flip-next-page-tickets {
  color: black;
  cursor: pointer;
  padding: 10px 8px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: rgb(253, 245, 232);
}

.flip-next-page-tickets:hover {
  background-color: rgb(253, 236, 206);
}