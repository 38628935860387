/* css for suggested_sentences view */

.total-ssgstd,
.total-sappr {
  display: block;
  /* margin: 10px auto; */
}

.stat-of-sentences {
  padding-left: 20px;
  width: fit-content;
  margin: 45px auto 0;
  text-align: left;
  font-size: 1.2em;
  border-left: 5px solid #b0c4de;
}
.sgstd-stcs {
  margin: 26px auto;
  font-size: 1.2em;
}

.ol-sgstd-stcs {
  border-top: 1px dashed gray;
  border-bottom: 1px dashed gray;
  text-align: left;
}

.ol-sgstd-stcs > li {
  padding: 0 10px;
  margin: 4px auto;
  cursor: pointer;
  background-color: bisque;  
}

.ol-sgstd-stcs li:hover {
  background-color: rgb(248, 195, 130);
}

.lvl-ofSgStc {
  display: inline-block;
  width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sgstd-stc {
  display: inline-block;
  width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.total-sapproved {
  color: green;
}

.total-sdisapproved {
  color: red;
}