/* css for tie sentence */

.choose-stc-lvl,
.stc-tip {
  display: block;
}

.choose-stc-lvl {
  margin: 8% auto 5%;
}

.check-stcs {
  margin: 4%;
}

#st-bt1 {
  background-color: rgb(144, 185, 91);
}

#st-bt2 {
  background-color: rgb(110, 110, 189);
}

#st-bt3 {
  background-color: rgb(194, 90, 90);
}



#st-bt1:hover,
#st-bt2:hover,
#st-bt3:hover {
  opacity: 0.8;
}


#st-bt1,
#st-bt2,
#st-bt3 {
  margin-right: 10px;
}

#div0 {
  margin: 2% auto;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 80%;
  max-width: 1000px;
}


#div0 {
  margin: 2% auto;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 80%;
  max-width: 1000px;
}

#div1 {
  margin: 8% 5% 1%;
  padding-bottom: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid rebeccapurple;
}

.emptyDiv {
  border-radius: 4px;
  margin: 4% 2% 4% 0%;
  height: 36px;
  width: 80px;
  border: 2px solid black;
  box-shadow: inset 1px 1px 2px rgb(151, 151, 151);
}

.insertedDiv {
  border-radius: 4px;
  background-color: rgb(243, 192, 83);
  color: rgb(255, 255, 255);
  margin: 4% 2% 4% 0%;
  border: 2px solid #ce9d61;
  font-size: 1.15em;
  padding: 1% 3%;
  align-content: stretch;
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
}

#div1 span {
  color: #3a2f2f;
  font-size: 1.25em;
  background-color: rgb(243, 192, 83);
  padding: 6px 15px;
  box-shadow: 0px 4px 4px rgb(71, 71, 71);
}

.draggableSpans {
  margin: 2%;
  word-break: keep-all;
  cursor: pointer;
  font-family: Helvetica, Tahoma, Arial, "STXihei", "华文细黑", "Microsoft YaHei", "微软雅黑", sans-serif;
}

.sentence-transl {
  background: #bdb2ca;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

@media (min-width: 769px) {
  #div1 {
    margin: 2% 5% 1%;
  }
}