/* css for games-list view */

.games-list li {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;
  box-shadow: 1px 1px 4px #666565;
}

.games-list li img {
  width: 30%;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #666565;
}

.games-list li .game-url {
  text-align: left;
  width: 70%;
  font-size: 2em;
  color: gray;
  padding-left: 20px
}

.games-list p.game-name {
  margin: 11px 2px;
  color: black;
}

.games-list .game-num {
  font-weight: bold;
  font-size: 1.6em;
  color: #464e65;
  margin-right: 6px;
}

.games-list p.desc {
  margin: 0;
  font-size: 0.62em;
}

.games-list li:hover {
  box-shadow: 0px 2px 7px #565656;
}