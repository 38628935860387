/* css for main view */

.about-us-flex {
  margin: 24px auto;
  display: flex;
  flex-direction: column;
}

.what-is-ki-tay,
.how-it-works {
  width: 95%;
}

.desc-card {
  box-shadow: 3px 3px 5px #f5eded;
  border-radius: 10px;
  border: 1px solid #d1d1ff;
  background-color: rgb(237, 240, 247);
  padding: 14px 18px;
  margin: 6px 10px;
  text-align: justify;
}

.desc-card p {
  color: #191515;
  font-size: 1.06em;
}

.desc-card h3 {
  color: rgb(75, 73, 73);
  margin-top: 0;
}

.project {
  width: 90%;
  margin: 10px auto;
}

.profile-head {
  margin: 6px auto;
}

.profile-img {
  height: 650px;
  background-image: url('/static/imgs/screenshots/profile_screenshot.png');
  box-shadow: 0 0 56px 57px ghostwhite inset;
}

.help-screenshot {
  height: 420px;
  background-image: url('/static/imgs/screenshots/help_project_screen.png');
  box-shadow: 0 0 36px 46px ghostwhite inset;
}

@media (min-width: 760px) {

  .what-is-ki-tay,
  .how-it-works {
    width: 45%;
  }
  .about-us-flex {
    flex-direction: row;
    justify-content: space-around;
  } 
}