/* css for approve_sentence view */

.stc-btns {
  margin: 20px auto 25px;
}

.new-stcmsg {
  display: block;
  margin: 20px auto 10px;
}

.approve-sbtn,
.disapprove-sbtn {
  width: 45%
}

.stc-label {
  display: block;
  margin-top: 10px;
}

.stc-txtarea {
  margin: 2px auto;
  width: 100%;
}

.approve-sbtn {
  background-color: rgb(80, 200, 80);
  float: left;
}

.disapprove-sbtn {
  background-color: rgb(231, 81, 81);
  float: right; 
}

.stc-lvl {
  margin-left: 10px;
  margin-right: 2px;
}