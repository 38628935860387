/* CSS FOR ADMIN PRODUCTS view */

.product-modal-content {
  width: 96%;
  margin: 60px auto;
  padding: 20px 30px;
  background-color: aliceblue;
}

.all-products-ul > li {
  display: flex;
  border-radius: 4px;
  margin: 4px 0;
  border: 1px solid gray;
  justify-content: space-between;
  background-color: #9ceedc;
  transition: transform 0.2s;
}

.all-products-ul > li:hover {
  background-color: #b5e0d1;
  transform: scale(1.02);
  cursor: pointer;
}

.clickable-product-div {
  width: 95%;
  text-align: left;
}

.clickable-product-div > span {
  margin: 2px 4px;
}

.clickable-product-div,
.delete-product-span {
  padding: 2px 10px;
}

.delete-product-span {
  cursor: pointer;
  border-radius: 4px;
  color: red;
  font-size: 1.2em;
  transition: transform 0.2;
}

.delete-product-span:hover {
  transform: scale(1.3);
  background-color: rgb(207, 105, 105);
}

.product-data-area {
  width: 100%;
  min-height: 60px;
}

@media(min-width: 769px) {

  .product-modal-content {
    width: 44%;
  }
}