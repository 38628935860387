
.textDiv {
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5%;
  width: 96%;
  margin: 0 auto;
  background-color: rgb(218, 238, 239);
  text-align: justify;
  font-size: 1.2em;
  line-height: 1.45em;
  font-family: "华文细黑", "Microsoft YaHei", "微软雅黑", sans-serif;
}

.textDiv p {
  margin: 6px 0px 0px;
}

.got-text {
  padding: 7px 10px;
  display: block;
  margin: 20px 20px 10px;
  border: none;
  background-color: #61cc7d;
  color: white;
}

.got-text:hover {
  cursor: pointer;
  background-color: #67c27f;
}

.txt-was-learned {
  color: #1fad1f;
  font-size: 0.95em;
  padding: 10px;
  display: block;
}

@media (min-width: 769px) {
  .textDiv {
    padding: 2%;
  }
}