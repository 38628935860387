/* css for add_to_dictionary */

.add-word-mydict-cont {
  max-width: 880px;
  margin: 0 auto;
  width: 96%;
}

.user-results {
  margin: 8px auto 10px;
}

.user-results b {
  color: #4a4444;
  display: block;
  margin: 8px auto 10px;
}

.search-db {
  display: flex;
}

.sbutt-add {
  margin-top: 5px;
  min-width: 100px;
  height: 45px;
  border-radius: 0 8px 8px 0px
}

.img-addmydict,
.img-added {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
}

.img-addmydict:hover {
  opacity: 0.8; 
  transform: scale(1.3);
}

.cringe {
  transform: scale(0.1);
}

@media (min-width: 769px) {

  .add-word-mydict-cont {
    width: 66%;
  }
}   