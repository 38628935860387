/* css for my_collections view */

.creating-coll-panel {
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.creating-coll-panel > div {
  width: 50%;
}

.creating-coll-panel hr.left-hr {
  border-color: #dcd0a4;
  width: 87%;
}

.creating-coll-panel hr.right-hr {
  border-color: #aadca4;
  width: 87%; 
}

.user-words-list {
  background-color: #f3f3cc;
}

.collection-itself {
  background-color: #daf7c5;
}

.collection-itself > h4 {
  margin-top: 8px;
}

.user-words-list > h4,
.collection-itself > h4 {
  color: #353131;
}

.user-words-list input {
  background-color: #f9f5e7 !important;
  border: 1px solid #d4cccc !important;
  padding: 13px 15px !important;
  margin-bottom: 0 !important;
  width: 97% !important;
  border-radius: 6px !important;
}

.user-dict-ul {
  width: 97%;
  margin: auto;
  text-align: left;
}

.user-dict-ul > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #1f1e1e;
  background-color: #f1e3af;
  margin: 11px 0;
  border: 1px solid #bf9797;
  border-radius: 6px;
  box-shadow: 1px 2px 4px #a2a2a2a6;
  transition: transform 0.2s;
}

.user-dict-ul > div:hover {
  background-color: #fbf1cf;
  transform: scale(1.04);
} 

.user-dict-ul span {
  padding: 2px 14px;
  font-size: 1.1em;
}

.user-dict-ul span:nth-child(1) {
  font-size: 1.27em;
  width: 28%;
}

.user-dict-ul span:nth-child(2) {
  width: 64%
}

.user-dict-ul button { 
  background-color: #f3f3cc;
  color: #4c545d;
  padding: 2px 8px;
  margin-right: 2px;
  border-radius: 7px;
}

.user-dict-ul button:hover {
  background-color: cornsilk;
}


.user-coll-ul > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #1f1e1e;
  background-color: #e8f3dc;
  margin: 11px 0;
  border: 1px solid #bf9797;
  border-radius: 6px;
  box-shadow: 1px 2px 4px #a2a2a2a6;
  transition: transform 0.2s;
}

.user-coll-ul > div:hover {
  background-color: #a7eaa7;
  transform: scale(1.04);
} 

.user-coll-ul span {
  padding: 2px 14px;
  font-size: 1.1em;
}

.user-coll-ul span:nth-child(1) {
  font-size: 1.27em;
  width: 28%;
}

.user-coll-ul span:nth-child(2) {
  width: 64%
}

.user-coll-ul button { 
  background-color: #f3d3c4;
  color: #4c545d;
  padding: 2px 8px;
  margin-right: 2px;
  border-radius: 7px;
}

.user-coll-ul button:hover {
  background-color: rgb(245, 169, 169);
  color: #d04747;
}

.coll-nm-inpt {
  width: 80%;
  font-weight: 700;
  color: #986e51;
  font-size: 1.4em;
  padding: 6px;
  margin: 12px auto 4px;
}

.coll-nm-inpt:empty::before {
  content: "Введите имя коллекции";
  color: lightgray;
  cursor: text;
}

.coll-message {
  font-weight: bold;
  margin-top: 13px;
  display: block;
  color: #655d5d;
  text-align: left;
  padding-left: 10px;
}

.save-coll-btn {
  padding: 14px;
  border-radius: 5px;
  width: 81%;
  background-color: #97dc97;
  color: #315a31;
  border: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.save-coll-btn:hover {
  transform: scale(1.02);
  background-color: #7be083;
  color: black;
}