/* css for donate view */

.donate-cont {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}

.donate-cont h1 {
  margin-top: 0;
}

.donate-cont form {
  margin-top: 5%;
}

.choose-payment-type {
  border-color: rgb(33, 129, 207);
}

.choose-payment-type label {
  padding: 1%;
}

#pay-label2 {
  margin-left: 5%;
}

.donate-choose-msg {
  border-radius: 2px;
  padding: 2px;
  background-color: rgb(122, 128, 211);
}

.transmit-payment {
  border-radius: 10px;
}

.transmit-payment:hover {
  color: rgb(255, 234, 113);
  background-color: rgb(160, 116, 201);
  box-shadow: 2px 2px 6px blueviolet,
              -2px -2px 6px blueviolet
}

.label-sum {
  color: rgb(0, 0, 0);
  float: left;
}

.label-comment {
  color: rgb(0, 0, 0);
  float: left;
}

.donate-inpt-1 {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px;
  border: none;
  background: #f1f1f1;
}

.crypto-address {
  padding: 2px;
  border-radius: 10px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.crypto-address:hover {
  background-color: rgb(235, 233, 211);
}

.crypto-assets {
  text-align: left;
  word-break: break-all;
}

.addrHide {
  position: absolute; 
  left:-9999px;
}

@media (min-width: 769px) {
  .donate-cont {
    width: 70%;
    max-width: 964px;
  }
}