/* css for suggest text view */

.text-cont {
  width: 90%;
  margin: 0 auto;
}

#sgst-text-btn {
  width: fit-content;
}

.sgst-text-lbl {
  float: left;
}

.suggtext-fieldset {
  width: 100%;
  padding-bottom: 0;
  margin: 3% auto;
  box-sizing: border-box;
}
.suggtext-fieldset label {
  padding: 1%;
}

#sgt-text-body-inpt {
  width: 100%;
}

@media (min-width: 769px) {
  .text-cont {
    width: 70%;
  }
}