/* css for admin_panel view */

.hello-admin {
  text-indent: 15px;
  font-size: 1.2em;
  font-weight: 700;
  color: #b1638e;
}

.bread-triangle {
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid#91c384;
  display: inline-block;
  margin-bottom: -9px;
  border-radius: 2px;
  margin-top: -1px;
}

.bread-back-triangle {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #f8f8ff;
  display: inline-block;
  margin-bottom: -11px;
  margin-top: -9px;
  margin-right: 6px;
  border-radius: 2px;
}

.breadcrumps {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  top: 8px;
  padding: 4px 16px;
  font-size: 1.2em;
}

.breadcrump {
  display: flex;
  width: fit-content;
  margin: 5px;
}

.breadcrump,
.breadcrump div {
  white-space: nowrap;
}

.breadcrump.bread-curr > div:not(.bread-triangle):not(.bread-back-triangle) {
  background-color: thistle;
}

.breadcrump.bread-curr > .bread-triangle {
  border-left-color: thistle;
}

.breadcrump > div:not(.bread-triangle):not(.bread-back-triangle) {
  display: inline-block;
  border-radius: 8px;
  background-color: #91c384;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 6px;
  font-size: 0.8em;
  box-shadow: 1px 1px 3px #9a9a9a;
  margin-left: -5px;
  align-content: center;
}

.breadcrump > div > a {
  color: white;
  font-weight: bold;
}

.breadcrump.secondary {
  margin-left: -12px;
  z-index: -1;
}

.breadcrump.secondary > div {
  padding-left: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.breadcrump.secondary.bread-curr {
  z-index: -2;
}

.breadcrump:hover:not(.bread-curr) > .bread-triangle {
  border-left-color: #c0e897 !important;
}

.breadcrump:hover > div:not(.bread-triangle) {
  background-color: #c0e897;
}

.ad-ul {
  font-size: 1.2em;
  width: 90%;
  margin: 20px auto;
  max-width: 880px;
}

.ad-ul li {
  border-radius: 10px; 
  background-color: #dbdce8;
  margin: 5px;
  padding: 10px;
  transition: transform 0.2s;
}

.ad-ul a {
  font-weight: 600;
  display: block;
  color: #584e58;
}
.ad-ul > li:nth-child(odd) {
  background-color: #efe9ef;
}

.ad-ul > li:hover {
  transform: scale(1.05);
  background-color: rgb(192, 193, 235);
}

.ad-ul > li:hover a {
  color: white;
}

.ad-ul.main > li:last-child {
  background-color: #ffa6a6;
  opacity: 0.9;
}

.ad-ul.main > li:last-child:hover {
  background-color:#f56d6d;
  opacity: 1;
}

.ad-ul li a > span {
  background-color: rgb(95, 95, 95);
  color: white;
  padding: 0px 6px;
  font-size: 0.9em;
  margin-left: 4px;
  border-radius: 2px;
}

.icons-panel {
  width: 70%;
  margin: 20px auto;
  text-align: left;
  padding: 0px 20px;
}

.icons-panel img {
  width: 40px;
  height: 40px;
}

.sent-num {
  display: inline-flex;
  align-items: center;
  border: 2px solid #8fc4db;
  padding: 4px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform .2s;
  margin-right: 6px;
}

/* .sent-num span {
  font-weight: bold;
  margin-left: 4px;
  background: #2e6b86;
  padding: 2px 6px;
  border-radius: 9px;
  color: white;
} */

.sent-num:hover {
  transform: scale(1.1);
}

/* 
.panel-count {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 0px;
  left: 244px;
  padding: 4px 14px 16px;
  background-color: #f0f0f1;
}

.panel-count h3 {
  margin: 10px 0 10px;
} */

/* .line-count {
  border-radius: 4px;
  padding: 0 0 0 2px;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.num-sticker {
  background-color: #f1a5c1;
  color: #fff7e9;
  border-radius: 4px;
  padding: 0 4px;
}

.line-count:hover {
  background-color: rgb(207, 218, 150);
} */

@media (min-width: 769px) {
  .ad-ul {
    width: 70%;
  }

  .bread-back-triangle {
    border-bottom: 17px solid transparent;
    border-bottom: 16px solid transparent;
  }

  .breadcrumps {
    display: flex;
    font-size: inherit;
    left: 18%;
  }


  .breadcrump > div:not(.bread-triangle):not(.bread-back-triangle) {
    font-size: 1.14em;
    padding: 6px 14px;
  }

  .bread-triangle {
    margin-top: 0;
    border-top: 18px solid transparent;
  }

}