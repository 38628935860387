/* CSS for AUDIO_WORD training */

.training-audio {
  margin-top: 44px;
  background-color: #ffeded;
  padding: 30px 10px;
  border-radius: 11px;
  color: #505050;
  border: 2px solid #d89b9b;
  position: relative;
}

.training-audio  .training-cont {
  display: none;
  width: 60%;
  margin: 8px auto 10px;
}

.training-audio .training-end {
  display: none;
}

.training-cont .word-typed {
  background: white;
  border-radius: 10px;
  margin-top: 15px;
}

.training-cont .word-typed:focus {
  background-color: #eaeaea;
}

.training-cont .words-count {
  margin-bottom: 12px;
  position: absolute;
  left: 16px;
  top: 10px;
  color: #797979;
  font-weight: 900;
}

.audio-w-card {
  display: none;
  font-size: 1.6em;
  border-radius: 10px;
  padding: 10px 30px 20px;
  box-shadow: 2px 2px 5px #c7bbbb;
  background-color: #fffbdc;
  width: fit-content;
  height: fit-content;
  max-width: 60%;
  margin: 18px auto 10px;
}

.flip-au-container .au-w-hiero {
  font-size: 1.8em;
  font-weight: 900;
  color: #bd5252;
  margin: 6px 0px 4px;
}

.flip-au-container .au-w-transcr {
  color: #a97d46;
  font-size: 1.4em;
}

.flip-au-container .au-w-transl {
  font-size: 1.2em;
}

.start-audio-tr-btn {
  margin: 30px auto 4px;
  background-color: #a7cff3;
  padding: 8px 13px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  transition: transform 0.2s;
}

.start-audio-tr-btn:hover {
  background-color: #c2e1fc;
  transform: scale(1.1);
}

.wrap-train-au span {
  cursor: pointer;
  font-size: 1.12em;
  display: inline-block;
  transition: transform 0.2s;
  opacity: 0.6;
}

.wrap-train-au span:hover {
  opacity: 1;
  transform: scale(1.1);
}

.wrap-train-au {
  font-size: 1.4em;
  color: #8c4242;
  font-weight: bold;
}

.check-audio-tr-btn {
  background: white;
  border: none;
  padding: 10px 20px;
  border-radius: 7px;
  color: #7ea02e;
  cursor: pointer;
  transition: transform 0.2s;
}

.check-audio-tr-btn:hover {
  transform: scale(1.1);
}


.flip-au-container {
  display: inline-block;
  perspective: 500px;
  cursor: pointer;
  margin: 10px;
}

.flip-au-container.no-flip {
  cursor: not-allowed;
}

/* front pane, placed above back */
.front-au {
  background-color: #fff9d5; 
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back-au {
  background-color: #fffadf;
  overflow: hidden;
	transform: rotateY(180deg);
}

	/* flip the pane when hovered */  
  .flip-au-container.flip .flipper-au {
		transform: rotateY(180deg);
  }

.flip-au-container, 
.front-au, 
.back-au {
  border-radius: 20px;
  min-height: 100px;
  min-width: 180px;
  padding: 0 10px 0;
}

.flip-au-container {
  padding: 0;
  position: relative;
  display: none;
  margin: 18px auto 0;
}

/* flip speed goes here */
.flipper-au {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
}

/* hide back of pane during swap */
.front-au, .back-au {
	backface-visibility: hidden;
  position: absolute;
	top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 2px 2px 5px #d6d3d3;
}

.training-end .end-message {
  font-weight: bold;
  font-size: 1.14em;
  color: #909851;
}

.training-end .repeat-tr-btn {
  background-color: #dce4f9;
  padding: 17px 14px;
  border: none;
  color: #736868;
  border-radius: 9px;
  cursor: pointer;
  transition: transform 0.2s;
}

.training-end .repeat-tr-btn:hover {
  transform: scale(1.1);
}