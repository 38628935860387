
/* css for texts list */

.level-select {
  text-align: left;
}

.texts-list {
  background-color: ghostwhite;
  width: 96%;
  margin: 0 auto;
}

.start-reading {
  text-align: right;
}

.start-reading a {
  display: block;
  color: rgb(163, 25, 8);
  text-align: right;
  padding: 15px 5px 2px
}

.start-reading a:hover {
  text-decoration: underline;
}

.textDiv-l {
  border-radius: 8px;
  padding: 25px 20px 14px;
  margin-top: 4%;
  background-color: rgb(218, 238, 239);
  font-family: Helvetica, Tahoma, Arial, "STXihei", "华文细黑", "Microsoft YaHei", "微软雅黑", sans-serif;
  text-align: left;
  box-shadow: 1px 1px 4px rgb(180, 180, 180);
}

.text-header h3 {
  margin: 0 0 6px 10px;
  color: rgb(61, 59, 81);
}

.text-body {
  /* hide text if it more than N lines  */
  overflow: hidden;
  color: #21162b;
  font-size: 1.21em;
  position: relative; 
  line-height: 1.36em;
  max-height: 4.4em; 
  text-align: justify;  
  margin-right: -0.5em;
  padding-right: 1em;
}

.text-body::before {
  width: fit-content;
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 2px;
  bottom: 0px;
}

.text-body p {
  margin: 4px 0px;
}

.text-body p::after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 3em;
  background: rgb(218, 238, 239);
}

