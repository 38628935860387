/* css for word-libraries view */

.custom-coll {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.custom-coll > a {
  font-size: 1.75em;
  margin-bottom: 8px;
}

.custom-coll > i {
  font-size: 0.96em;
}

.collection-div a {
  font-size: 1.75em;
  color: #414448;
}

.collection-div {
  border: 1px solid #d6d5c4;
  background-color: #fcfdd2;
  border-radius: 16px;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 20px auto;
  padding: 10px;
  transition-property: transform;
  transition-duration: 0.2s;
}

.collection-div:hover {
  transform: scale(1.04);
  box-shadow: 3px 4px 5px #e2dfdf;
  background-color: #fafba9;
}

.img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28px;
  height: 100%;
  width: 26%;
}

.img-cont img {
  border-radius: 8px;
  width: 86%;
}

@media (min-width: 769px) {

  .collection-img {
    height: 100%;
  }

}