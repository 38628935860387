/* css for my_collections view */

.new-coll-bt {
  margin: auto;
  background-color: #5cbe71;
  transition: transform 0.2s;
}

.new-coll-bt:hover {
  transform: scale(1.05);
  background-color: #c6dc9e;
}

.my-colls-ol {
  margin: 34px auto;
  width: 90%;
}

.my-colls-ol li {
  display: flex;
  margin: 7px 0;
  transition: transform 0.2s;
}

.my-colls-ol li div.coll-nm {
  background-color: #d6ecb0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 8px 20px;
  display: inline-block;
  width: 58%;
  color: #352d2d;
}

.my-colls-ol li div.coll-nm:hover {
  cursor: pointer;
  background-color:#e7f5c5;
}

.my-colls-ol .del-coll-btn {
  background-color: #ff8c8c;
  padding: 8px 16px;
  border: none;
  border-left: 2px solid #8da779;
  transition: transform .2s;
  color: #6b7b6b;
}

.my-colls-ol .del-coll-btn:hover {
  background-color: #ff6a6a;
  color: white;
  transform: scale(1.1);
}

.my-colls-ol li button:last-child {
  background-color: #c6dc9e;
  padding: 8px 16px;
  border: none;
  border-left: 2px solid #9dc181;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  transition: transform .2s;
  color: #6b7b6b;
}

.my-colls-ol li button:last-child:hover {
  transform: scale(1.1);
  background-color: rgb(255, 255, 255);
}

.pop-pop {
  transform: scale(1.1);
}


@media (min-width: 769px) {
  .my-colls-ol li {
    font-size: 1.16em;
  }
  .my-colls-ol li button {

  }
}

