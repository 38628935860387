/* css for suggested_articles view */

.list-ofsArt {
  display: block;
  margin: 28px auto;
}

.ul-sgtart > li {
  cursor: pointer; 
  text-align: left;
}

.ul-sgtart > li:hover {
  background-color: rgb(234, 238, 241);
}

.ul-sgtart:first-child {
  border-top: 1px dashed gray;
}

.ul-sgtart:last-child {
  border-bottom: 1px dashed gray;
}

.sgst-artHeader {
  padding: 0 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sgst-artBody p {
  margin: 0;
  display: inline-block;
  width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}