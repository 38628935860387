.create-applic-btn {
    background: url("/static/imgs/flame.jpg") no-repeat 40% 70% transparent;
    text-shadow: 0px 0px 50px #870b0b;
    background-size: 110%;
    width: 100%;
    padding: 10px;
    font-size: 1.88em;
    color: #e31414d9;
    background-color: #000000;
    cursor: pointer;
    font-weight: 700;
}

.list-of-battles li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    font-size: 1.16em;
    background-color: #dededc;
}

.list-of-battles li button {
    background-color: #b27b68;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}

.list-of-battles li button:hover {
    transform: scale(1.03);
    transition: transform 0.2s;
}

.applications-total {
    text-align: left;
    width: 100%;
    font-size: 1.1em;
}

.create-applic-btn:hover {
    transform: scale(1.03);
    transition: transform 0.2s;
}

@media (min-width: 769px) {
    .create-applic-btn {
        padding: 20px;
    }
}

