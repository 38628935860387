/* css for my_dictionary view */

.my-dict-panel {
  display: flex; 
  justify-content: space-between;
}

.my-dict-panel select {
  max-width: 200px;
}

.words-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.words-table tr:not(:last-child) {
  border-bottom: 1px solid rgb(238, 191, 115);
}

.words-table > tbody> tr:hover {
  background-color: rgb(250, 255, 199);
  cursor: pointer;
}

.table-transl {
  transition-property: transform;
  transition-duration: 0.3s;
}

.pop-transl {
  transform: scale(1.3);
}

.no-coincedence-found {
  text-align: left;
  color: rgb(172, 161, 161);
}

.words-table td {
  padding: 2px 0;
  font-size: 1.13em;
}

.words-table > thead > tr > th {
  padding-bottom: 8px;
}

.table-word {
  word-break: keep-all;
}

.wprogress-cell {
  display: inline-flex;
  width: fit-content;
  border: 1px solid rgba(113, 31, 221, 0.877);
  border-right: none;
  box-shadow: -1px -1px 1px rgba(171, 149, 235, 0.46),
  1px 1px 1px rgba(124, 136, 250, 0.46);
  background-color: rgb(0, 255, 0);
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
}

.wprogress-cell:hover {
  box-shadow: 2px 2px 5px steelblue;
  transform: scale(1.2);
}

.squares {
  width: 8px;
  height: 12px;
  display: inline-block;
  border-right: 1px solid rgba(124, 136, 250, 0.877);
}

.progress-0 div:nth-of-type(1n+1),
.progress-1 div:nth-of-type(1n+2),
.progress-2 div:nth-of-type(1n+3),
.progress-3 div:nth-of-type(1n+4),
.progress-4 div:nth-of-type(1n+5),
.progress-5 div:nth-of-type(1n+6) {
  background-color: rgb(255, 255, 255);
}

.word-del-span {
  padding: 0 8px 0;
  font-size: 1.4em;
  color: red;
  float: right;
  cursor: pointer;
  border-radius: 20%;
  transition-property: transform;
  transition-duration: 0.2s;
}

.word-del-span:hover {
  transform: scale(1.5);
  background-color: rgb(223, 223, 223);
}

/* Modal content styles*/

.my-dict-modal-cont {
  border-radius: 10px;
  background-color: #fefefe;
  margin: 10% auto;
  padding: 15px 20px 25px;
  border: 1px solid #888;
  width: 48%;
}

.my-dict-modal-cont > h3 {
  color: #4e4646;
  font-size: 1.5em;
  margin-top: -6px;
  margin-bottom: 20px;
}

.my-dict-modal-cont > hr {
  width: 58%; 
  margin: 12px auto 8px;
}

.w-its-lf {
  font-size: 2.4em;
  color: #884747;
}

.my-dict-modal-cont span:not(:first-of-type) {
  display: block;
  margin: 8px auto;
}

.transcr-del-modal {
  font-size: 1.4em;
  color: #524e48;
}

.transl-del-modal {
  color: rgb(226, 189, 64);
  font-size: 1.2em;
  margin-top: 16px;
}

.progress-info {
  color: rgb(245, 102, 102);
}

.my-dict-action-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 28px 0 10px;
}

.my-dict-action-buttons > button {
  margin: 0;
}

.my-dict-action-buttons > button:hover {
  background-color: rgb(63, 89, 158);
}

.wr-d {
  background-color: #fbf7c9;
  box-shadow: 2px 2px 6px #eaeaea;
  border-radius: 10px;
  width: fit-content;
  padding: 14px 42px;
  margin: 0 auto;
}

@media (min-width: 769px) {

  .mydict-del-word-span,
  .mydict-coll,
  .word-diccoll {
    display: table-cell;
  }

  /* modal styles */
  .mydict-modalcont {
    width: 34%;
  }

  .twobtns-dict {
    width: 64%;
  }
}