/* css for index view */

/* --- GENERAL STYLES ---- */

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: dodgerblue;
}

p {
  text-indent: 15px;
}

hr {
  border: 1px solid #f1f1f1;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

legend {
  background-color: black;
  color: white;
}

footer {
  background-color: rgb(245, 241, 251);
  padding: 5px;
}

::selection {
  background: #bebaf3; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #bebaf3; /* Gecko Browsers */
}

/* Full-width input fields */
input[type=text], input[type=password], input[type=number] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

.kitay-h1 {
  color: rgb(92, 118, 190);
}

.kitaybtn {
  text-align: center;
  cursor: pointer;
  background-color: #7d8ebd;
  color: white;
  border: none;
  opacity: 0.9;
  margin: 14px auto 24px;
  padding: 14px 42px;
  width: fit-content;
  max-width: 246px;
  border-radius: 5px;
  transition: transform 0.4s;
}

.kitaybtn:hover {
  transform: scale(1.05);
  background-color: rgb(151, 175, 238);
}

.wrap-toright {
  margin: 0 auto;
  background-color: ghostwhite;
}

.inner-content {
  position: relative;
  margin: 0 auto;
  max-width: 880px;
  width: 96%;
  min-height: calc(100vh - 84px);
  padding: 3% 0 10%;
  font-size: 0.8em;
}

.menu-header {
  margin: 0;
  padding: 16px 16px 4px;
}

.main-mn-text {
  font-family: none;
}

.words-in-dc {
  position: relative;
  display: block;
  margin: 18px 16px;
  color: #505b77;
  font-weight: bold;
  cursor: help;
}

.words-in-dc > span {
  position: relative;
  display: inline-block;
  background-color: #c1cdee;
  padding: 2px 6px;
  margin-left: 4px;
  color: white;
  animation-name: skew;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.social-m {
  /* display: none; */ 
  position: fixed;
  bottom: 28px;
  right: 20px;
  /* margin-top: 50px; */
  /* padding: 8px 16px; */
}


.social-m img {
  width: 30px;
  Height: 30px;
  opacity: 0.6;
}

.social-m img:hover {
  scale: 1.1;
  opacity: 1;
}

@keyframes skew {
  0% {top: -2px; transform: rotate(6deg);}
  50% {top: 0px; transform: rotate(-6deg);}
  100% {top: -2px; transform: rotate(6deg);}
} 

/* for modal */

.background-ofmodal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.wrap-close {
  text-align: right;
}

.close-ofmodal {
  display: inline-block;
  color: #aaa;
  margin: 0 4px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
}

.close-ofmodal:hover,
.close-ofmodal:focus {
  color: black;
  transform: scale(1.1);
  text-decoration: none;
}

.message,
.error {
  display: none;
  margin: 0 auto 10px;
  padding: 5px 10px;
  border-radius: 6px;
  width: fit-content;
  max-width: 70%;
  text-align: justify;

  left: 16%;
  top: 15px;
  position: fixed;
  z-index: 1000;
  opacity: 0;
}

.message {
  color: rgb(10, 142, 194);
  background-color: rgb(220, 238, 192);
  border: 2px solid rgb(117, 207, 124);
  text-shadow: 1px 1px 2px rgb(145, 216, 116);
  
}

.error {
  color: rgb(47, 47, 47);
  background-color: rgb(243, 234, 241);
  border: 2px solid rgb(255, 151, 151);
  text-shadow: 1px 1px 2px white;
}

.chinese-font {
  font-family: 'Noto Serif SC', serif;
}

.error.fade-in-and-out,
.message.fade-in-and-out {
  display: block;
  animation-name: fade-in-and-out;
  animation-duration: 5s;
}

.loading-embed {
  visibility: hidden;
  z-index: 10000;
  display: flex;
  width: fit-content;
  max-width: 65%;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 55vw;
  top: 44vh;
  transform: translate(-50%, -44%);
  border: 1px solid #d0c5e0;
  background-color: white;
  border-radius: 14px;
  box-shadow: 2px 2px 6px #a2a2a3;
  padding: 5px 0px;
}

.loading-embed span {
  font-size: 1.05em;
  margin: 8px 21px 8px 8px;
}


@keyframes fade-in-and-out {
  0% {opacity: 0;}
  20% {opacity: 1;}
  80% {opacity: 1;}
  100% {opacity: 0;}
}

/* Scrollbar styles */

::-webkit-scrollbar {
  width: 8px;
}
  
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgb(180, 195, 224);  
}
  
::-webkit-scrollbar-thumb:hover {
  background: #7581aa;  
}

/* Mobile Phones */

.menu-wrap {
  background-color: ghostwhite;
}

.main-nav a {
  display: block;
  text-transform: uppercase;
  color: rgb(99, 124, 206);
}

.main-nav > li {
  margin: 3px 0;
  padding: 10px 0;
  background-color: rgb(236, 239, 248);
}

.main-nav > li > a {
  font-size: 0.94em;
}

.main-nav li:last-of-type {
  color: rgb(214, 72, 72);
}

.main-nav li:hover {
  background-color: rgb(216, 222, 238);
}

.main-nav li:hover > a > span,
.main-nav li:hover > .menu-icon:before {
  color: white; 
}

.heart-menu,
.heart-main {
  margin-left: 2px;
  width: 26px;
  opacity: 0.9;
}

.menu-icon:before {
  margin-right: 8px;
}

.one-new {
  margin-top: 30px;
  padding: 30px 22px;
  text-align: left;
  border-radius: 13px;
  background-color: #fffdf6;
  box-shadow: 2px 4px 8px #d0d0d0;
}

.new-body {
  text-align: justify;
}

.new-heading {
  margin: 0;
  margin-bottom: 14px;
  color: #e8a08f;
}

.datePublished-news {
  font-size: 0.95em;
  margin-top: 14px;
  color: rgb(73, 73, 73);
  text-align: right;
}

.old-news {
  text-align: right;
  margin-top: 20px
}

.old-news span {
  border: 1px solid #a6b3e2;
  border-radius: 8px;
  color: #292323;
  padding: 4px 10px;
}

.old-news span:hover {
  cursor: pointer;
  background-color: #e6ecfd;
}

/* check if needed */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.error-cont {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.error-header {
  color: #de5a57;
}

.error-cont img {
  width: 30%;
  margin: 0 auto;
}

.error-cont div {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.error-p-msg {
  font-size: 1.16em;
}

.cookie-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(to left,#a4ccd0 1%,#e2e2ff 99%);
  background-color: #b6c8f9;
  opacity: 0.97;
  color: #21195d;
  padding: 20px 2px 8px;
  box-shadow: 0 -2px 3px #d1d3d8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.wrap-txt-cookie {
  width: 85%;
  margin: 0 auto;
  max-width: 1348px;
}

.cookie-footer p {
  margin: 0;
  background-color: #f1f8ff;
  color: #505363;
  border-radius: 6px;
  font-size: 1.05em;
  text-align: justify;
  text-indent: 0;
  padding: 8px 34px;
}

.cookie-btns-wrap {
  text-align: right; 
}

.cookie-btn {
  color: #333638;
  border: none;
  margin: 4px 2px;
  padding: 6px 12px;
  font-size: .94em;
  background-color: #ddefff;
  border-radius: 4px;
  cursor: pointer;
}

.cookie-btn:hover {
  background-color: #6394c3;
  color: #fff;
}

/* WIDER SCREENS */
@media screen and (min-width: 769px) {

  .loading-embed {
    max-width: 45%;
  }


  /* == MAIN LAYOUT == */

  .inner-content {
    width: 86%;
    font-size: inherit;
  }

  .wrap-toright {
    margin-left: 203px;
  }

  /* left menu  */

  .menu-wrap {
    background: linear-gradient(to bottom, #f1f1f1 20%, ghostwhite 80%);
    min-width: 203px;
    max-width: 300px;
    width: 18%;
    height: 100%;
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
  }

  .menu-header {
    margin: 0.60em 0;
    background-color: #f1f1f1;
  }

  .main-nav {
    font-size: 1.16em;
  }

  .main-nav li.current {
    background-color: rgb(193, 205, 238);
    color: white;
  }

  .main-nav a {
    text-transform: none;
    padding: 8px 16px;
    color: #2f2d2d;
  }

  .main-nav li {
    margin: 0;
    padding: 0;
    background-color: inherit;
  }

  .main-nav > li > a {
    font-size: 1em;
  }
  

  .main-nav .icofont-sign-out:before,
  .main-nav .icofont-sign-out > span {
    color: #da4f4f;
  }

  .main-nav li:not(.current) .icofont-sign-in:before,
  .main-nav li:not(.current) .icofont-sign-in > span {
    color: #519a51;
  }

  .menu-icon:before {
    color: #7689bd;
    font-size: 1.05em;
  }

  .profile-icon:before {
    color: #020202;
    font-size: 1.25em;
    margin-right: 4px;
    opacity: 0.6;
  }

  .profile-icon-smaller:before {
    color: #020202;
    font-size: 1.1em;
    margin-right: 4px;
    opacity: 0.6;
  }

  .main-nav li:last-of-type a:hover > span,
  .main-nav li:last-of-type:hover .menu-icon:before {
    color: white;
  }
  
  .error-cont {
    flex-direction: row;
  }

  .error-cont img {
    width: 27%;
  }

  footer {
    background: linear-gradient(to left, rgb(245, 241, 251) 1%, ghostwhite 99%);
  }

  .social-m {
    bottom: 4%;
    right: 4%;
  }
}
/* === END MEDIA 769px === */
