/* css for create ticket and others.. */

.ticket-div {
  margin: 28px auto;
  max-width: 880px;
  width: 96%;
}

.ticket-div label:first-child {
  margin-right: 6px;
  margin-bottom: 10px;
  font-weight: bold;
}

.ticket-div input {
  margin: 0;
}

.ticket-div select {
  margin-bottom: 10px;
}

.info-message-for-user {
  display: none;
  border: 2px solid #1eb10b;
  font-size: 1.1em;
  background-color: beige;
  padding: 10px;
  border-radius: 10px;
}

.ticket-label {
  display: block;
  margin: 16px auto 4px;
  font-weight: bold; 
}

.msg-ticket {
  margin: 24px auto;
}

.donot-repeat {
  display: block;
  padding: 0 8px;
  border-left: 2px solid red;
  margin-bottom: 26px;
}

.ticketMessage {
  box-sizing: border-box;
  display: block;
  margin: 12px auto 20px;
  width: 100%;
}