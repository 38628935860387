/* css for suggested_texts view */

.texts-stat {
  display: block;
  font-size: 1.2em;
  width: fit-content;
  margin: 36px auto 14px;
  padding-left: 10px;
  border-left: 4px solid rgb(43, 174, 226);
  text-align: left;
}

.total-txapvd {
  color: green;
}

.total-txdisapvd {
  color: red;
}

.sgstd-texts {
  margin: 10px auto 20px;
  font-size: 1.2em;
}

.ol-sgstd-txts > li {
  margin: 2px 0;
  padding: 4px 0;
  text-align: left;
  cursor: pointer;
}

.ol-sgstd-txts > li:nth-child(odd) {
  background-color: rgb(253, 245, 220);
}

.ol-sgstd-txts > li:hover {
  background-color: bisque
}

.ol-sgstd-txts > li:first-child {
  border-top: 1px solid rgb(119, 119, 119);
}

.ol-sgstd-txts > li:last-child {
  border-bottom: 1px solid rgb(119, 119, 119);;
}

.sgstd-thead {
  padding-left: 5px;
  width: 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sgstd-tbody {
  padding-left: 10px;
  width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}