/* Css for addcolection view */

.info-msg {
  display: block; 
  margin: 10px auto;
}

.sort-inputs {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
}

.add-new-coll-btn {
  margin: 0;
  border: none;
  cursor: pointer;
  color: green;
  border-radius: 4px;
  padding: 8px;
  background-color: rgb(114, 223, 114);
}

.add-new-coll-btn:hover {
  background-color: rgb(138, 238, 138);
}

.all-colls-ul li {
  display: flex;
  text-align: left;
  padding-right: 8px;
  cursor: pointer;
  color: #3051ab;
  border-radius: 4px;
  align-items: center;
  border: 1px solid transparent;
}

.all-colls-ul li:nth-child(odd) {
  background-color: rgb(132, 233, 137);
}

.all-colls-ul li:hover {
  border: 1px dashed blue;
  background-color: rgb(230, 255, 206);
}

.delete-coll-span {
  color: red;
  font-size: 1.4em;
  padding: 0 4px;
}

.delete-coll-span:hover {
  transform: scale(1.5);
  background-color: rgb(253, 149, 131);
}

.clickable-coll-div {
  width: 100%;
  padding: 7px 10px;
}

/* for modal */
.collection-modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px 24px 25px;
  border: 1px solid #888;
  width: 37%;
}

.collection-modal-coll-name-inpt {
  width: 70%;
}

.collection-modal-act-btn:hover {
  background-color: rgb(112, 236, 112);;
}

.collection-modal-coll-label {
  display: block;
  margin-bottom: 9px;
}